import { ADD_ACCOUNT, EMAIL_UPDATED, PASSWORD_CHANGED, NOTIFICATIONS_CHANGED, RESET } from '../actionTypes';

const initialState = {};

export default function(state = initialState, action) {

  switch (action.type) {
    case ADD_ACCOUNT: {
      return {...state, ...action.account};
    }
    case EMAIL_UPDATED: {
      return {...state, emailUpdated: action.timestamp};
    }
    case PASSWORD_CHANGED: {
      return {...state, passwordChanged: action.timestamp};
    }
    case NOTIFICATIONS_CHANGED: {
      return {...state, notificationsChanged: action.timestamp};
    }
    case RESET: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
