import constants from '../constants';

// eslint-disable-next-line no-control-regex
const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi;

export default {

  isValid(errors) {
    errors = errors || {};
    
    var keys = Object.keys(errors);
    for (var i = 0; i < keys.length; ++i)
    {
      var value = errors[keys[i]];

      if (typeof value === "object") {
        var isChildValid = this.isValid(value);
        if (isChildValid === false)
          return false;
      }

      else if (value)
        return false;
      
    }
    return true;
  },

  isNumber(value) {
    var number = Number(value);
    return !isNaN(number) && isFinite(number);
  },

  isInt(value) {
    var isNumber = this.isNumber(value);
    return isNumber && value % 1 === 0;
  },

  isOutOfRange(value, min, max) {
    return Number(value) < min || Number(value) > max;
  },

  exceedsMaxLength(value, len) {
    return value && value.length > len;
  },

  isNotInList(value, list) {
    var found = false;
    const keys = Object.keys(list);

    for (var i = 0; i < keys.length; ++i)
      if (value === keys[i]) {
        found = true;
        break;
      }
      
    return !found;
  },

  validateAll(fields, sections) {
    fields = fields || {};

    var errors = {};

    var s = {
      profiles: 'validateProfiles',
      savings: 'validateSavings',
      allocations: 'validateAllocations',
      income: 'validateIncome',
      expenses: 'validateExpenses'
    };

    Object.keys(s).forEach(k => {
      if (!sections || sections.indexOf(k) >= 0)
        errors[k] = this[s[k]](fields[k], fields && fields.profiles && fields.profiles.hasSecondary ? true : false);
      
    });

    return errors;
  },

  validateProfiles(profiles) {
    profiles = profiles || {};

    var errors = {};

    errors.primary = this.validateProfile(profiles.primary);

    if (profiles.hasSecondary)
      errors.secondary = this.validateProfile(profiles.secondary);

    return errors;
  },

  validateProfile(profile) {
    profile = profile || {};

    var errors = {};

    if (profile.name && this.exceedsMaxLength(profile.name, 50))
      errors.name = 'Too long';

    if (!profile.yearOfBirth || !this.isInt(profile.yearOfBirth))
      errors.yearOfBirth = 'This is required';
    else if (this.isOutOfRange(profile.yearOfBirth, constants.maximumYearOfBirth, constants.minimumYearOfBirth))
      errors.yearOfBirth = 'Invalid year of birth';
      
    const minimumRetirementAge = profile.yearOfBirth ? Math.max(constants.currentYear - Number(profile.yearOfBirth), constants.minimumRetirementAge) : constants.minimumRetirementAge;
    if (!profile.retirementAge || !this.isInt(profile.retirementAge))
      errors.retirementAge = 'This is required';
    else if (this.isOutOfRange(profile.retirementAge, minimumRetirementAge, constants.maximumRetirementAge))
      errors.retirementAge = 'Invalid retirement age';
    
    if (profile.gender && this.isNotInList(profile.gender, constants.genders))
      errors.gender = 'Invalid gender';
    
    if (profile.health && this.isNotInList(profile.health, constants.healthLevels))
      errors.health = 'Invalid health';

    if (profile.state && this.isNotInList(profile.state, constants.states))
      errors.state = 'Invalid state';

    return errors;
  },

  validateSavings(savings, hasSecondary) {
    savings = savings || {};

    var errors = {};
    
    if (savings.savings && (!this.isNumber(savings.savings) || +savings.savings < 0))
      errors.savings = 'Invalid amount';

    if (savings.primaryContributions && (!this.isNumber(savings.primaryContributions) || +savings.primaryContributions < 0))
      errors.primaryContributions = 'Invalid amount';
    if (hasSecondary && savings.secondaryContributions && (!this.isNumber(savings.secondaryContributions) || +savings.secondaryContributions < 0))
      errors.secondaryContributions = 'Invalid amount';

    return errors;
  },

  validateAllocations(allocations) {
    allocations = allocations || {};

    var errors = {};
    var sum = 0;
    
    constants.allocations.forEach(a => {
      if (allocations[a.id])
        if (!this.isNumber(allocations[a.id]))
          errors[a.id] = 'Invalid allocation';
        else
          sum += +allocations[a.id];
    });

    if (sum !== 100)
      errors.total = 'Must add up to 100%';
    
    return errors;
  },

  validateIncome(income, hasSecondary) {
    income = income || {};

    var errors = {};

    if (income.hasSocialSecurity && income.socialSecurity)
      errors.socialSecurity = this.validateIncomeGroup(income.socialSecurity, hasSecondary);

    if (income.hasPension && income.pension)
      errors.pension = this.validateIncomeGroup(income.pension, hasSecondary);

    if (income.hasAnnuity && income.annuity)
      errors.annuity = this.validateIncomeGroup(income.annuity, hasSecondary);

    if (income.hasOtherIncome && income.otherIncome)
      errors.otherIncome = this.validateIncomeGroup(income.otherIncome, hasSecondary);

    return errors;
  },

  validateIncomeGroup(incomeGroup, hasSecondary) {
    var errors = {};

    if (incomeGroup) {
      if (incomeGroup.primary)
        errors.primary = this.validateIncomeItem(incomeGroup.primary)
      if (incomeGroup.secondary && hasSecondary)
        errors.secondary = this.validateIncomeItem(incomeGroup.secondary);
    }

    return errors;
  },

  validateIncomeItem(incomeItem) {
    var errors = {};

    if (incomeItem && (incomeItem.amount || incomeItem.commencementAge)) {
      if (!incomeItem.amount)
        errors.amount = 'This is required';
      else if (!this.isNumber(incomeItem.amount) || +incomeItem.amount < 0)
        errors.amount = 'Invalid amount';

      if (!incomeItem.commencementAge || !this.isNumber(incomeItem.commencementAge) || +incomeItem.commencementAge < 0)
        errors.commencementAge = 'This is required';
    }

    return errors;
  },

  validateExpenses(expenses, hasSecondary) {
    expenses = expenses || {};

    var errors = {};

    if (!expenses.jointBasicLivingExpenses)
      errors.jointBasicLivingExpenses = 'This is required';
    else if (!this.isNumber(expenses.jointBasicLivingExpenses) || +expenses.jointBasicLivingExpenses < 0)
      errors.jointBasicLivingExpenses = 'Invalid amount';

    if (expenses.primaryBasicLivingExpenses && (!this.isNumber(expenses.primaryBasicLivingExpenses) || +expenses.primaryBasicLivingExpenses < 0))
      errors.primaryBasicLivingExpenses = 'Invalid amount';
    if (hasSecondary && expenses.secondaryBasicLivingExpenses && (!this.isNumber(expenses.secondaryBasicLivingExpenses) || +expenses.secondaryBasicLivingExpenses < 0))
      errors.secondaryBasicLivingExpenses = 'Invalid amount';
      
    if (expenses.primaryMedicalExpenses && (!this.isNumber(expenses.primaryMedicalExpenses) || +expenses.primaryMedicalExpenses < 0))
      errors.primaryMedicalExpenses = 'Invalid amount';
    if (hasSecondary && expenses.secondaryMedicalExpenses && (!this.isNumber(expenses.secondaryMedicalExpenses) || +expenses.secondaryMedicalExpenses < 0))
      errors.secondaryMedicalExpenses = 'Invalid amount';

    return errors;
  },


  validateSignUp(fields) {
    var errors = {};

    if (!fields.email)
      errors.email = 'This is required';
    else if (!fields.email.match(emailRegex))
      errors.email = 'Invalid email address';

    if (!fields.password)
      errors.password = 'This is required';
    else if (fields.password.length < 8)
      errors.password = 'Must be at least 8 characters';
    else if (fields.password.length > 100)
        errors.password = 'Must be at most 100 characters';

    return errors;
  },

  validateLogin(fields) {
    var errors = {};

    if (!fields.email)
      errors.email = 'This is required';
    else if (!fields.email.match(emailRegex))
      errors.email = 'Invalid email address';

    if (!fields.password)
      errors.password = 'This is required';
    else if (fields.password.length < 8)
      errors.password = 'Must be at least 8 characters';
    else if (fields.password.length > 100)
      errors.password = 'Must be at most 100 characters';

    return errors;
  },

  validateForgotPassword(fields) {
    var errors = {};

    if (!fields.email)
      errors.email = 'This is required';
    else if (!fields.email.match(emailRegex))
      errors.email = 'Invalid email address';

    return errors;
  },
  
  validateResetPassword(fields) {
    var errors = {};

    if (!fields.password)
      errors.password = 'This is required';
    else if (fields.password.length < 8)
      errors.password = 'Must be at least 8 characters';
    else if (fields.password.length > 100)
      errors.password = 'Must be at most 100 characters';

    return errors;
  },

  validateUpdateEmail(fields) {
    var errors = {};

    if (!fields.email)
      errors.email = 'This is required';
    else if (!fields.email.match(emailRegex))
      errors.email = 'Invalid email address';

    return errors;
  },

  validateChangePassword(fields) {
    var errors = {};

    if (!fields.currentPassword)
      errors.currentPassword = 'This is required';

    if (!fields.newPassword)
      errors.newPassword = 'This is required';
    else if (fields.newPassword.length < 8)
      errors.newPassword = 'Must be at least 8 characters';
    else if (fields.newPassword.length > 100)
      errors.newPassword = 'Must be at most 100 characters';

    return errors;
  },


  validateSocialSecurity(fields) {
    var errors = {};

    if (!fields.currentAge)
      errors.currentAge = 'This is required'
    else if (!this.isNumber(fields.currentAge) || +fields.currentAge < 0)
      errors.currentAge = 'Invalid amount';
    else if (this.isOutOfRange(fields.currentAge, 14, 120))
      errors.currentAge = 'Must be between 14 and 120';

    if (!fields.retirementAge)
      errors.retirementAge = 'This is required'
    else if (!this.isNumber(fields.retirementAge) || +fields.retirementAge < 0)
      errors.retirementAge = 'Invalid amount';
    else if (this.isOutOfRange(fields.retirementAge, 14, 120))
      errors.retirementAge = 'Must be between 14 and 120';

    if (!fields.claimAge)
      errors.claimAge = 'This is required'
    else if (!this.isNumber(fields.claimAge) || +fields.claimAge < 0)
      errors.claimAge = 'Invalid amount';
    else if (this.isOutOfRange(fields.claimAge, 14, 120))
      errors.claimAge = 'Must be between 14 and 120';

    if (!fields.currentEarnings)
      errors.currentEarnings = 'This is required'
    else if (!this.isNumber(fields.currentEarnings) || +fields.currentEarnings < 0)
      errors.currentEarnings = 'Invalid amount';

    return errors;
  },

  validateSocialSecurityEarning(fields) {
    var errors = {};
    if (!fields.amount)
      errors.amount = 'This is required'
    else if (!this.isNumber(fields.amount) || +fields.amount < 0)
      errors.amount = 'Invalid amount';
    return errors;
  }
}
