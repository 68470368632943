import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ADD_REPORT_LATEST, ADD_REPORT, ADD_REPORTS } from '../store/actionTypes';
import { Row, Col, Form } from 'react-bootstrap';
import { SwappingRule } from '../components/Shared/Layout';

import ScoreTile from './Report/ScoreTile';
import PreviousScoresTile from './Report/PreviousScoresTile';
import ScoreExplained from './Report/ScoreExplained';
import ReportInputs from './Report/ReportInputs';
import ScoreStatistics from './Report/ScoreStatistics';
import ReportScenarios from './Report/ReportScenarios';
import { Tip } from './Report/Tips';

import api from '../services/api';
import moment from 'moment';

const Report = ({ reportId, report, tips, reports, dispatch, navigate }) => {
  const [loading, setLoading] = useState(false);
  
  const hasReport = report ? true : false;
  const isReady = hasReport && report.ready ? true : false;
  //const hasSecondary = isReady && report.secondaryYearOfBirth && report.secondaryRetirementAge ? true : false;

  useEffect(() => {
    var intervalId = null;

    function refresh() {
      (reportId ? api.getReport(reportId) : api.getReportLatest())
        .then(r => {
          if (r.data && r.data.ready) {
            dispatch({ type: (reportId ? ADD_REPORT : ADD_REPORT_LATEST), report: r.data });
            clearInterval(intervalId);
          }
        });
    }

    if (!report) {
      setLoading(true);
      (reportId ? api.getReport(reportId) : api.getReportLatest())
        .then(r => {
          dispatch({ type: (reportId ? ADD_REPORT : ADD_REPORT_LATEST), report: r.data });
          setLoading(false);

          if (r.data && !r.data.ready) {
            intervalId = setInterval(refresh, 2500);
          }
        })
        .catch(e => {
          setLoading(false);
        });

      return function cleanup() {
        if (intervalId) clearInterval(intervalId);
      }
    }
  }, [report, reportId, dispatch, setLoading]);

  useEffect(() => {
    setLoading(true);
    api.getReports()
    .then(r => {
      dispatch({ type: ADD_REPORTS, reports: r.data })
      setLoading(false);
    })
    .catch(e => {
      setLoading(false);
    });
  }, [dispatch]);


  return <>
    <Row>
      <Col>

        <Row className="align-items-center">
          <Col className="p-3">
            <h5 className="text-uppercase mb-0">Your RISE Score Report</h5>
          </Col>
          <Col xs={12} sm="auto" className="px-3">
            <Row>
              <Col xs={true} sm="auto" className="pr-3">
                {reports && reports.length > 0 && 
                  <Form.Control as="select" value={report ? report.id : reportId} onChange={(e) => navigate(`/report/${e.target.value}`)}>
                    {reports.map((r, i) =>
                      <option key={i} value={r.id}>
                        Report {moment(r.created).format('MMM D, YYYY')} - Score {r.ready ? r.score : '---'}
                      </option>
                    )}
                  </Form.Control>
                }
              </Col>
              {/* <Col xs="auto">
                <Button variant="outline-primary">Print Report</Button>
              </Col> */}
            </Row>
          </Col>
        </Row>

        <Row>
          <Col sm={6} className="p-3 d-flex align-items-center">
            <ScoreTile score={isReady ? report.score : null} created={isReady ? report.created : null} />
          </Col>

          <SwappingRule breakpoint="sm" />

          <Col sm={6} className="p-3 d-flex justify-content-between" style={{flexDirection: 'column'}}>
            <PreviousScoresTile report={report} reports={reports} />
          </Col>
        </Row>

        {isReady && <>

          <div className="px-3">
            <hr className="my-0" />
          </div>

          <Row>
            <Col sm={12} className="p-3">
              <ScoreExplained score={report.score} />
            </Col>
          </Row>

          <div className="px-3">
            <hr className="my-0" />
          </div>

          <Row>
            <Col sm={12}>
              <h5 className="text-uppercase mb-0 mt-3 mx-3">What was considered</h5>
              <ReportInputs report={report} />
            </Col>
          </Row>

          <div className="px-3">
            <hr className="my-0" />
          </div>

          <Row>
            <Col sm={12}>
              <ScoreStatistics data={report} />
            </Col>
          </Row>
      
          {report && report.scenarios && report.scenarios.length > 0 && <>
            <div className="px-3">
              <hr className="my-0" />
            </div>

            <ReportScenarios report={report} />
          </>}

          <div className="px-3">
            <hr className="my-0" />
          </div>

          <Row>
            <Col className="p-3">
              <h5 className="text-uppercase">Ways That May Help Improve Your Score</h5>
              <p>
                The following tips may help improve your situation.
                You can learn about these tips and more using the <a href="/simulator">RISE Score Simulator</a>.
              </p>

              <p className="m-0">Before taking action or to find out more, schedule a meeting with your financial advisor and bring this report with you to your meeting.</p>
            </Col>
          </Row>

          <Row>
            {tips && tips.map((t, i) => <React.Fragment key={i}>
              {i > 0 && i % 4 !== 0 && <SwappingRule breakpoint={i % 2 === 0 ? 'md' : 'sm'} />}
              {i > 0 && i % 4 === 0 &&
                <div className="px-3 w-100">
                  <hr className="my-0" />
                </div>
              }

              <Tip tip={t} />
            </React.Fragment>)}
          </Row>
        </>}

      </Col>
    </Row>
  </>;
};

export default connect((state, { reportId }) => {
  const report = reportId ? state.reports.byKey[reportId] : state.reports.latest;
  const tips = (report && report.tips) || [];
  tips.sort((a, b) => a.improvement < b.improvement ? 1 : -1);

  return {
    report,
    tips,
    reports: state.reports.summaries,
    profile: state.profile
  };
})(Report);
