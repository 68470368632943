import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { NumberFormatControl, SubmitButtons } from './Shared';
import { InvalidFeedback } from '../Shared/Helpers';
import { SwappingRule } from '../Shared/Layout';
import { initializeDefaults } from '../../helpers';

const Blurb = () => {
  return <>
    <p>
      Finally, let's talk about your expenses in retirement.
      We've separated basic living expenses and medical expenses because medical expenses tend to increase over your lifetime at a different rate than basic expenses.
    </p>
    {/* <p>
      To help you estimate your basic living expenses, try creating a retirement budget: Link to Budget Tool. 
    </p>
    <p>
      Unsure about medical expenses?  Check out this map of average health costs in the U.S. Link to Map Tool.
    </p> */}
  </>;
}

export default function Expenses({ value, errors, loading, hasSecondary = false, submitLabel, cancelLabel, showCancel, buttonAlignment, onChange, onSubmit, onCancel }) {

  value = initializeDefaults(value, {
    jointBasicLivingExpenses: '',
    primaryBasicLivingExpenses: '',
    secondaryBasicLivingExpenses: '',
    primaryMedicalExpenses: '',
    secondaryMedicalExpenses: ''
  });

  errors = initializeDefaults(errors, {});

  function setValue(name, v) {
    value[name] = v;
    onChange({ ...value });
  }

  return <>
    <Row>
      <Col lg={8} className="p-3">
        <Form noValidate autoComplete="off" onSubmit={(e) => { e.preventDefault(); onSubmit(); return false; }}>
          <div className="d-lg-none mb-3">
            <Blurb />
            <hr />
          </div>

          <h5 className="text-uppercase">Basic Living Expenses</h5>
          <p>
            Estimate {!hasSecondary ? 'your' : 'you and your spouse\'s'} basic monthly living expenses in retirement, in today's dollars.
            This will include expenses for housing, food, utilities, etc, in order to maintain your standard of living.
          </p>
          
          <Form.Group controlId="formJointBasicLivingExpenses">
            <Form.Row>
              <Col sm={6}>
                <Form.Label>{!hasSecondary ? 'Your' : 'Combined'} Expenses</Form.Label>
                <NumberFormatControl value={value.jointBasicLivingExpenses} numberStyle="currency" append="/ month" onChange={e => setValue('jointBasicLivingExpenses', e.target.value)} isInvalid={!!errors.jointBasicLivingExpenses} disabled={loading} />
                <InvalidFeedback error={errors.jointBasicLivingExpenses} />
              </Col>
              {hasSecondary &&
                <Col xs={12}>
                  <Form.Text>These are basic living expenses that both you and/or your spouse will require in retirement. For example, basic housing, food and utilities.</Form.Text>
                </Col>
              }
            </Form.Row>
          </Form.Group>

          {hasSecondary &&
            <Form.Group>
              <Form.Row>
                <Col sm={6}>
                  <Form.Group controlId="formPrimaryBasicLivingExpenses" className="mb-0">
                    <Form.Label>Your Additional Expenses</Form.Label>
                    <NumberFormatControl value={value.primaryBasicLivingExpenses} numberStyle="currency" append="/ month" onChange={e => setValue('primaryBasicLivingExpenses', e.target.value)} isInvalid={!!errors.primaryBasicLivingExpenses} disabled={loading} />
                    <InvalidFeedback error={errors.primaryBasicLivingExpenses} />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group controlId="formSecondaryBasicLivingExpenses" className="mb-0">
                    <Form.Label>Your Spouse's Additional Expenses</Form.Label>
                    <NumberFormatControl value={value.secondaryBasicLivingExpenses} numberStyle="currency" append="/ month" onChange={e => setValue('secondaryBasicLivingExpenses', e.target.value)} isInvalid={!!errors.secondaryBasicLivingExpenses} disabled={loading} />
                    <InvalidFeedback error={errors.secondaryBasicLivingExpenses} />
                  </Form.Group>
                </Col>

                <Col xs={12}>
                  <Form.Text>These are additional basic living expenses that would stop in the event of you or your spouse's passing.</Form.Text>
                </Col>
              </Form.Row>
            </Form.Group>
          }

          <Form.Row>
            <Col xs={12}>
              <Form.Text>The average American's living expenses are $3,813 a month.</Form.Text>
            </Col>
          </Form.Row>

          <hr />
          
          <h5 className="text-uppercase">Medical Expenses</h5>
          <p>Estimate {!hasSecondary ? 'your' : 'you and your spouse\'s'} medical expenses in retirement to the best of your abilities, in today's dollars.</p>

          <Form.Row>
            <Form.Group as={Col} sm={6} controlId="formPrimaryMedicalExpenses">
              <Form.Label>Your Expenses</Form.Label>
              <NumberFormatControl value={value.primaryMedicalExpenses} numberStyle="currency" append="/ month" onChange={e => setValue('primaryMedicalExpenses', e.target.value)} isInvalid={!!errors.primaryMedicalExpenses} disabled={loading} />
              <InvalidFeedback error={errors.primaryMedicalExpenses} />
            </Form.Group>

            {hasSecondary &&
              <Form.Group as={Col} sm={6} controlId="formSecondaryMedicalExpenses">
                <Form.Label>Your Spouse's Expenses</Form.Label>
                <NumberFormatControl value={value.secondaryMedicalExpenses} numberStyle="currency" append="/ month" onChange={e => setValue('secondaryMedicalExpenses', e.target.value)} isInvalid={!!errors.secondaryMedicalExpenses} disabled={loading} />
                <InvalidFeedback error={errors.secondaryMedicalExpenses} />
              </Form.Group>
            }
          </Form.Row>

          <Form.Row>
            <Col xs={12}>
              <Form.Text>The average American's medical expenses are $500 a month.</Form.Text>
            </Col>
          </Form.Row>

          <hr />

          <SubmitButtons {...{loading, submitLabel, cancelLabel, showCancel, buttonAlignment, onSubmit, onCancel}} />
        </Form>

      </Col>

      <SwappingRule breakpoint="sm" />

      <Col lg={4} className="p-3 d-none d-lg-block">

        <div>
          <Blurb />
        </div>

      </Col>
    </Row>
  </>
}