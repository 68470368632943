import constants from './constants';

export function parseQuery(queryString) {
  var query = {};
  var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString || '').split('&');
  for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

export function initializeDefaults(value, defaults) {
  value = value || {};

  Object.keys(defaults).forEach(k => {
    value[k] = value[k] === null || value[k] === undefined
      ? defaults[k]
      : value[k];
  });

  return value;
}

export function scoreIndex(score) {
  for (var i = 0; i < constants.scoreRanges.length; ++i) {
    var scoreRange = constants.scoreRanges[i];
    if (scoreRange.min <= score && score <= scoreRange.max)
      return i;
  }
  return null;
}

export function formatValue(value, style = 'decimal') {
  var number = parseFloat(value);
  var options = {
    style,
    minimumFractionDigits: number === parseInt(value) ? 0 : 2,
    maximumFractionDigits: 2
  };

  if (style === 'currency') {
    options.currency = 'USD';
  }
  else if (style === 'percent') {
    number /= 100;
  }

  return !isNaN(number)
    ? number.toLocaleString(undefined, options)
    : '';
};

export function findPreSelectedAllocation(value) {
  for (var i = 0; i < constants.preallocations.length; ++i) {
    var preallocation = constants.preallocations[i];
    var equal = true;

    for (var j = 0; j < constants.allocations.length; ++j) {
      var allocation = constants.allocations[j];
      if ((parseFloat(value[allocation.id]) || 0) !== (preallocation.allocations[allocation.id] || 0))
      {
        equal = false;
        break;
      }
    }

    if (equal)
      return i;
  }

  return null;
};