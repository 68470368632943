import React from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { SubmitButtons } from './Shared';
import { InvalidFeedback } from '../Shared/Helpers';
import { SwappingRule } from '../Shared/Layout';
import constants from '../../constants';


const Individual = ({ title, showName = true, hasClose, value, errors, loading, onChange, onClose }) => {
  value =               value || {};
  value.name =          value.name || '';
  value.gender =        value.gender || '';
  value.health =        value.health || '';
  value.yearOfBirth =   value.yearOfBirth || '';
  value.retirementAge = value.retirementAge || '';
  value.state =         value.state || '';
  
  const yearsOfBirth = [...Array(constants.maximumAge - constants.minimumAge + 1).keys()]
    .map(y => constants.minimumYearOfBirth - y);

  const minimumRetirementAge = value.yearOfBirth ? Math.max(constants.currentYear - Number(value.yearOfBirth), constants.minimumRetirementAge) : constants.minimumRetirementAge;
  const retirementAges = [...Array(constants.maximumRetirementAge - minimumRetirementAge + 1).keys()]
    .map(y => minimumRetirementAge + y);

  function setValue(name, v) {
    value[name] = v;
    onChange({ ...value });
  }

  return <>

    {hasClose &&
      <button className="close" onClick={onClose} type="button" disabled={loading}><span>&times;</span></button>
    }

    <h5 className="text-uppercase mb-3">{title || 'About You'}</h5>

    <Form.Row>
      {showName &&
        <Form.Group as={Col} controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" value={value.name} onChange={e => setValue('name', e.target.value)} isInvalid={!!errors.name} disabled={loading} />
          <InvalidFeedback error={errors.name} />
        </Form.Group>
      }

      <Form.Group as={Col} controlId="formYearOfBirth">
        <Form.Label>Year of Birth</Form.Label>
        <Form.Control as="select" className="custom-select" value={value.yearOfBirth} onChange={e => setValue('yearOfBirth', e.target.value)} isInvalid={!!errors.yearOfBirth} disabled={loading}>
          <option></option>
          {yearsOfBirth.map((y, i) => <option key={i} value={y}>{y}</option>)}
        </Form.Control>
        <InvalidFeedback error={errors.yearOfBirth} />
      </Form.Group>

      <Form.Group as={Col} controlId="formRetirementAge">
        <Form.Label>Anticipated Age of Retirement</Form.Label>
        <Form.Control as="select" className="custom-select" value={value.retirementAge} onChange={e => setValue('retirementAge', e.target.value)} isInvalid={!!errors.retirementAge} disabled={loading}>
          <option></option>
          {retirementAges.map((y, i) => <option key={i} value={y}>{y}</option>)}
        </Form.Control>
        <InvalidFeedback error={errors.retirementAge} />
      </Form.Group>
    </Form.Row>

    <Form.Row>
      <Form.Group as={Col} controlId="formGender">
        <Form.Label>Gender</Form.Label>
        <Form.Control as="select" className="custom-select" value={value.gender} onChange={e => setValue('gender', e.target.value)} isInvalid={!!errors.gender} disabled={loading}>
          <option></option>
          {Object.keys(constants.genders).map((k, i) => <option key={i} value={k}>{constants.genders[k]}</option>)}
        </Form.Control>
        <InvalidFeedback error={errors.gender} />
      </Form.Group>
      
      <Form.Group as={Col} controlId="formHealth">
        <Form.Label>Health</Form.Label>
        <Form.Control as="select" className="custom-select" value={value.health} onChange={e => setValue('health', e.target.value)} isInvalid={!!errors.health} disabled={loading}>
          <option></option>
          {Object.keys(constants.healthLevels).map((k, i) => <option key={i} value={k}>{constants.healthLevels[k]}</option>)}
        </Form.Control>
        <InvalidFeedback error={errors.health} />
      </Form.Group>
    </Form.Row>

    <Form.Group controlId="formState">
      <Form.Label>State</Form.Label>
      <Form.Control as="select" className="custom-select" value={value.state} onChange={e => setValue('state', e.target.value)} isInvalid={!!errors.state} disabled={loading}>
        <option></option>
        {Object.keys(constants.states).map((k, i) => <option key={i} value={k}>{constants.states[k]}</option>)}
      </Form.Control>
      <InvalidFeedback error={errors.state} />
    </Form.Group>

  </>
}

const Blurb = () => {
  return <>
    Tell us about yourself.
    The details you provide help in calculating your RISE Score.
    Your year of birth and anticipated age of retirement are required.
    Your gender and level of health may impact your life expectancy which contributes to your RISE Score.
    The state you reside in may also impact pre and post-retirement taxation.
  </>
}

const AddSpouse = ({ loading, onClick }) => {
  return <>
    <hr />
    <p>You may also add a spouse to better refine your RISE Score as a couple.</p>
    <Button variant="outline-primary" onClick={onClick} disabled={loading}>Add a Spouse</Button>
  </>;
}

export default function Profiles({ value, errors, loading, showName, submitLabel, cancelLabel, showCancel, buttonAlignment, onChange, onSubmit, onCancel }) {
  value =               value || {};
  value.primary =       value.primary || {};
  value.hasSecondary =  value.hasSecondary || false;
  value.secondary =     value.secondary || {};

  errors =              errors || {};
  errors.primary =      errors.primary || {};
  errors.secondary =    errors.secondary || {};

  function setValue(name, v) {
    value[name] = v;
    onChange({ ...value });
  }

  return <>
    <Row>
      <Col lg={8} className="p-3">
        <Form noValidate autoComplete="off" onSubmit={(e) => { e.preventDefault(); onSubmit(); return false; }}>
          <div className="d-lg-none mb-3">
            <Blurb />
            <hr />
          </div>

          <Individual showName={showName} value={value.primary} errors={errors.primary} loading={loading} onChange={v => setValue('primary', v)} />

          {value.hasSecondary &&
            <>
              <hr />
              <Individual showName={showName} title="About Your Spouse" hasClose={true} value={value.secondary} errors={errors.secondary} loading={loading} onChange={v => setValue('secondary', v)} onClose={v => setValue('hasSecondary', false)} />
            </>
          }

          {!value.hasSecondary &&
            <div className="text-center d-lg-none">
              <AddSpouse loading={loading} onClick={() => setValue('hasSecondary', true)} />
            </div>
          }

          <hr />

          <SubmitButtons {...{loading, submitLabel, cancelLabel, showCancel, buttonAlignment, onSubmit, onCancel}} />
        </Form>
      </Col>

      <SwappingRule breakpoint="sm" />

      <Col lg={4} className="p-3 d-none d-lg-block">
        <p>
          <Blurb />
        </p>

        {!value.hasSecondary &&
          <div className="mb-3">
            <AddSpouse loading={loading} onClick={() => setValue('hasSecondary', true)} />
          </div>
        }
      </Col>

    </Row>
  </>
}