import React, { useState, useEffect } from 'react';
import { ResponsiveContainer, LineChart, Line, Tooltip, XAxis, YAxis } from 'recharts';
import moment from 'moment';

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    let date = moment.utc(label * 1000);

    return (
      <div className="recharts-default-tooltip" style={{margin: 0,
        padding: 10,
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        whiteSpace: 'nowrap'
      }}>
        <strong>{date.month() === 0 ? date.format('MMM \'YY') : date.format('MMM')}</strong>

        {payload.map((p, i) => 
          <p key={i} className="label mb-0" style={{color: p.stroke}}>
            RISE Score: {p.value}
          </p>
        )}
      </div>
    );
  }
  return null;
};

const PreviousScoresTile = ({ report, reports }) => {
  const [previousScores, setPreviousScores] = useState([]);

  useEffect(() => {
    if (report && reports && reports.length > 0) {
      const lastOfMonth = {};
      let currentReportFound = false;
      for (let i = 0; i < reports.length; ++i) {
        var summary = reports[i];
        
        if (!currentReportFound) {
          if (summary.id === report.id)
            currentReportFound = true;
          else
            continue;
        }

        let timestamp = moment.utc(summary.created);
        let startOf = timestamp.startOf('month');
        let unix = startOf.unix();
        if (!lastOfMonth[unix])
          lastOfMonth[unix] = summary;
      }

      const months = 6;
      const prev = [];
      let currentStartOf = moment.utc(report.created).startOf('month');

      for (let m = 0; m < months; ++m) {
        let key = currentStartOf.unix();
        if (lastOfMonth[key])
          prev.push({ date: key, summary: lastOfMonth[key] || null });
        currentStartOf = currentStartOf.add(-1, 'month');
      }

      prev.reverse();

      setPreviousScores(prev);
    }
  }, [report, reports, setPreviousScores])

  return <>
    {report && reports && reports.length && <>
      <h5 className="text-uppercase mb-3">Previous RISE Scores</h5>

      <ResponsiveContainer maxHeight={240}>
        <LineChart
          data={(previousScores || []).map(r => ({ name: r.date, value: r.summary ? r.summary.score : null }))}
          margin={{ top: 5, right: 30, left: 20, bottom: 0 }}
        >
          <XAxis dataKey="name" type="number"
            tickFormatter={(v) => {
              let date = moment.utc(v * 1000);
              return date.month() === 0 ? date.format('MMM \'YY') : date.format('MMM');
            }}
            domain={[
              moment.utc(report.created).startOf('month').add(-5, 'month').unix(),
              moment.utc(report.created).startOf('month').unix()
            ]}
            ticks={[
              moment.utc(report.created).startOf('month').add(-5, 'month').unix(),
              moment.utc(report.created).startOf('month').add(-4, 'month').unix(),
              moment.utc(report.created).startOf('month').add(-3, 'month').unix(),
              moment.utc(report.created).startOf('month').add(-2, 'month').unix(),
              moment.utc(report.created).startOf('month').add(-1, 'month').unix(),
              moment.utc(report.created).startOf('month').unix()
            ]}/>
          <YAxis domain={[0, 850]} hide />
          <Tooltip content={CustomTooltip} />
          <Line dataKey="value" stroke="#ff6400" activeDot={{ r: 8 }} isAnimationActive={false}  />
        </LineChart>
      </ResponsiveContainer>
    </>}
  </>;
}

export default PreviousScoresTile;