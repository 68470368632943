import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Button, Card, Form, Modal, Alert } from 'react-bootstrap';
import { InvalidFeedback } from './Shared/Helpers';
import ScoreTile from './Report/ScoreTile';
import ScoreExplained from './Report/ScoreExplained';
import ScoreStatistics from './Report/ScoreStatistics';
import Error from './Shared/Error';
import { LoadingModal } from '../components/Profile/Shared';
import { SwappingRule } from '../components/Shared/Layout';
import api from '../services/api';
import validators from '../services/validators';

import blurredDetailsXs from '../images/blurred-details-xs.png';
import blurredDetailsSm from '../images/blurred-details-sm.png';
import blurredDetailsMd from '../images/blurred-details-md.png';
import blurredDetailsLg from '../images/blurred-details-lg.png';
import blurredDetailsXl from '../images/blurred-details-xl.png';

const SignUpCTA = ({ messageType = 'generic', bulletType = 'short', showHeading = true, showCTA = true, onClick }) => {
  return <>
    {showHeading && <h5 className="text-uppercase mb-3">Sign Up Today</h5>}

    <p>
      {messageType === 'generic' && 'With a free RISE Account, you\'ll be able to:'}
      {messageType === 'reveal' && 'Reveal these details and more with a free RISE Account. You\'ll also be able to:'}
    </p>
    <ul>
      <li>Keep track of your score with detailed and historical reports</li>
      <li>Receive tips on how you may improve your score</li>
      <li>Compare your score with others in similar age groups</li>
      {bulletType === 'short' &&
        <>
          <li><span className="pseudo-link" onClick={onClick}>And more...</span></li>
        </>
      }
      {bulletType === 'long' &&
        <>
          <li>Link your investment accounts to automatically keep track of your retirement goals</li>
        </>
      }
    </ul>

    {showCTA &&
      <div className="text-center">
        <Button onClick={onClick}>Create Your Free Account Today</Button>
      </div>
    }
  </>;
}

const SignUpModal = ({ show = false, scoreId, onClose, navigate }) => {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  function handleClose() {
    if (!loading)
    {
      onClose();
      setFields({});
      setErrors({});
    }
  }

  function handleSubmit() {
    const e = validators.validateSignUp(fields);
    setErrors(e);

    if (validators.isValid(e)) {
      setLoading(true);
      api.registerUser({...fields, scoreId})
        .then(r => {
          setLoading(false);
          navigate('/login', { state: { newAccount: true } });
        })
        .catch(e => {
          e.status === 400 && setErrors(e.errors);
          setLoading(false);
        });
    }
  }

  return <Modal show={show} onHide={handleClose} size="lg">
    <Modal.Header closeButton>
      <Modal.Title as="h5" className="text-uppercase">
        Create an account
      </Modal.Title>
    </Modal.Header>

    <Modal.Body className="p-0">

      <Row>
        <Col lang={6} className="p-3">
          <SignUpCTA bulletType="long" showHeading={false} showCTA={false} />
        </Col>

        <SwappingRule breakpoint="lg" />
        
        <Col lg={6} className="p-4 p-lg-5 justify-content-center d-flex align-items-center">
          <div className="w-100">
            <Form noValidate autoComplete="off" onSubmit={(e) => { e.preventDefault(); handleSubmit(); return false; }}>

              {!!errors[''] && <Alert variant="danger">{errors['']}</Alert>}

              <Form.Group as={Col} controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" value={fields.email || ''} onChange={e => setFields({...fields, email: e.target.value})} isInvalid={!!errors.email} disabled={loading} />
                <InvalidFeedback error={errors.email} />
              </Form.Group>

              <Form.Group as={Col} controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" value={fields.password || ''} onChange={e => setFields({...fields, password: e.target.value})} isInvalid={!!errors.password} disabled={loading} />
                <InvalidFeedback error={errors.password} />
              </Form.Group>

              <Row className="justify-content-between">
                <Col xs="auto">
                  <Button variant="outline-primary" onClick={handleClose} disabled={loading}>
                    Cancel
                  </Button>
                </Col>
                <Col xs="auto">
                  <Button variant="primary" type="submit" disabled={loading}>
                    Create Account
                  </Button>
                </Col>
              </Row>

            </Form>
          </div>
        </Col>
      </Row>

    </Modal.Body>
  </Modal>
}

const BlurredDetailCTA = ({ onClick }) => {
  return <div style={{position: 'relative'}}>
    <img src={blurredDetailsXs} className="w-100 d-sm-none" style={{userSelect: 'none'}} alt="" />
    <img src={blurredDetailsSm} className="w-100 d-none d-sm-inline d-md-none" style={{userSelect: 'none'}} alt="" />
    <img src={blurredDetailsMd} className="w-100 d-none d-md-inline d-lg-none" style={{userSelect: 'none'}} alt="" />
    <img src={blurredDetailsLg} className="w-100 d-none d-lg-inline d-xl-none" style={{userSelect: 'none'}} alt="" />
    <img src={blurredDetailsXl} className="w-100 d-none d-xl-inline" style={{userSelect: 'none'}} alt="" />

    <Row className="justify-content-center" style={{position: 'absolute', top: 0, left: 0, right: 0}}>
      <Col sm={9} md={8} lg={6}>
        <Card className="my-5 mx-3">
          <Card.Body>
            <SignUpCTA onClick={onClick} />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </div>;
}

export default function ScoreSummary({ scoreId, navigate, location }) {
  const [showSignUp, setShowSignUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(location.state);

  const load = useCallback(() => {
    setLoading(true);
    setError(false);
    api.getScore(scoreId)
      .then(r => {
        setLoading(false);
        setData(r.data);
      })
      .catch(e => {
        if (e.status === 404)
          navigate('/not-found');
        else
          setError(true);
        setLoading(false);
      });
  }, [scoreId, navigate]);

  useEffect(() => {
    if ((!data || Object.keys(data).length === 0) && !!scoreId) {
      load();
    }
  }, [data, scoreId, load]);

  return <>
    {!loading && data && <>
      <Row>
        <Col>

          <Row className="align-items-center">
            <Col className="p-3">
              <h5 className="text-uppercase mb-0">Your RISE Score Summary</h5>
            </Col>
          </Row>

          <Row>
            <Col sm={6} className="p-3 d-flex align-items-center">
              <ScoreTile score={data.score} created={data.created} />
            </Col>

            <SwappingRule breakpoint="sm" />

            <Col sm={6} className="p-3">
              <SignUpCTA onClick={() => setShowSignUp(true) } />
            </Col>
          </Row>

          <div className="px-3">
            <hr className="my-0" />
          </div>

          <Row>
            <Col sm={12} className="p-3">
              <ScoreExplained score={data.score} />
            </Col>
          </Row>

          <div className="px-3">
            <hr className="my-0" />
          </div>

          <Row>
            <Col sm={12}>
              <ScoreStatistics data={data} />
            </Col>
          </Row>
      
          <div className="px-3">
            <hr className="my-0" />
          </div>

          <Row>
            <Col xs={12}>
              <BlurredDetailCTA onClick={() => setShowSignUp(true)} />
            </Col>
          </Row>

      
        </Col>
      </Row>

      <SignUpModal show={showSignUp} scoreId={scoreId} onClose={() => setShowSignUp(false)} navigate={navigate} />
    </>}

    <LoadingModal loading={loading} message={'Loading your RISE Score'} />

    {error && <Error onClick={load} />}
  </>;
}
