import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form, Modal } from 'react-bootstrap';
import { NumberFormatControl } from '../Profile/Shared';
import { InvalidFeedback } from '../Shared/Helpers';
import validators from '../../services/validators';

export default function IncomeModal({ value, onAccept, onUnset, onClose }) {
  const { open, age, isSet } = value;
  const [fields, setFields] = useState({ amount: value.amount || '' });
  const [liveValidation, setLiveValidation] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => setFields({ amount: value.amount || '' }), [value]);

  useEffect(() => {
    if (liveValidation === true) {
      var e = validators.validateSocialSecurityEarning(fields);
      setErrors(e);
    }
  }, [fields, liveValidation]);

  function handleAccept() {
    var errors = validators.validateSocialSecurityEarning(fields);
    if (!validators.isValid(errors)) {
      setErrors(errors);
      if (liveValidation === false) setLiveValidation(true);
    }
    else {
      onAccept && onAccept(age, +fields.amount);
      setLiveValidation(false);
    }
  }

  function handleUnset() {
    isSet && onUnset && onUnset(age);
    setLiveValidation(false);
  }

  function handleClose() {
    onClose && onClose();
    setLiveValidation(false);
  }

  return <Modal show={open} onHide={() => onClose()}>
    <Modal.Header closeButton>
      <Modal.Title as="h5" className="text-uppercase">
        Earnings At Age {age}
      </Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <p>What were your earnings at age {age}?</p>
      <Row>
        <Form.Group as={Col} xs={8} md={6} controlId="formEmail">
          <Form.Label>Amount</Form.Label>
          <NumberFormatControl value={fields.amount || ''} numberStyle="currency" onChange={e => setFields({ ...fields, amount: e.target.value })} isInvalid={!!errors.amount} />
          <InvalidFeedback error={errors.amount} />
        </Form.Group>
      </Row>
    </Modal.Body>

    <Modal.Footer style={{ justifyContent: 'space-between' }}>
      <Col xs="auto">
        {isSet && <Button className="unsetButton" variant="outline-danger" onClick={handleUnset}>Unset</Button>}
      </Col>
      <Col xs="auto">
        <Button className="cancelButton mx-2" variant="outline-primary" onClick={handleClose}>Cancel</Button>    
        <Button className="applyButton" onClick={handleAccept}>Apply</Button>
      </Col>
    </Modal.Footer>
  </Modal>
}