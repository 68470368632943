import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { NumberFormatControl } from '../Profile/Shared';
import { InvalidFeedback, Tooltip } from '../Shared/Helpers';
import validators from '../../services/validators';


export default function InputsView({ value, onNext }) {
  const [fields, setFields] = useState(value || {});
  const [errors, setErrors] = useState({});
  const [liveValidation, setLiveValidation] = useState(false);

  useEffect(() => {
    setFields(value || {});
  }, [value]);

  useEffect(() => {
    if (liveValidation === true) {
      var e = validators.validateSocialSecurity(fields);
      setErrors(e);
    }
  }, [fields, liveValidation]);

  function handleNext() {
    var errors = validators.validateSocialSecurity(fields);
    if (!validators.isValid(errors)) {
      setErrors(errors);
      if (liveValidation === false) setLiveValidation(true);
    }
    else
      onNext && onNext({
        currentAge: +fields.currentAge,
        retirementAge: +fields.retirementAge,
        claimAge: +fields.claimAge,
        currentEarnings: +fields.currentEarnings
      });
  }

  function setValue(key, value) {
    var newFields = {...fields};
    newFields[key] = value;
    setFields(newFields);
  }

  return <Form noValidate autoComplete="off" onSubmit={(e) => { e.preventDefault(); handleNext(); return false; }}>
    <Row>
      <Col xs={12} className="p-4">
        <div>
          <h2>Estimate your Social Security Benefits</h2>
          <p>Enter your current age, the age you will stop working (retirement age) and the age that you expect to begin claiming benefits.</p>
        </div>

        <hr />

        <div>
          <h3>About you</h3>
          <Form.Row>
            <Form.Group as={Col} xs={4} md={3} lg={2} controlId={`formCurrentAge`}>
              <Form.Label>Current Age</Form.Label>
              <NumberFormatControl className="currentAgeField" value={fields.currentAge} isInvalid={!!errors.currentAge}
                onChange={e => setValue('currentAge', e.target.value)} onKeyUp={e => setValue('currentAge', e.target.value)}
              />
              <InvalidFeedback error={errors.currentAge} />
            </Form.Group>

            <Form.Group as={Col} xs={4} md={3} lg={2} controlId={`formRetirementAge`}>
              <Form.Label>Retirement Age</Form.Label>
              <NumberFormatControl className="retirementAgeField" value={fields.retirementAge} isInvalid={!!errors.retirementAge}
                onChange={e => setValue('retirementAge', e.target.value)} onKeyUp={e => setValue('retirementAge', e.target.value)}
              />
              <InvalidFeedback error={errors.retirementAge} />
            </Form.Group>

            <Form.Group as={Col} xs={4} md={3} lg={2} controlId={`formClaimAge`}>
              <Form.Label>
                Claiming Age
                <Tooltip content={<>The age at which you plan to start claiming your social security benefit</>}>
                  <i className="far fa-question-circle"></i>
                </Tooltip>
              </Form.Label>
              <NumberFormatControl className="claimingAgeField" value={fields.claimAge} isInvalid={!!errors.claimAge}
                onChange={e => setValue('claimAge', e.target.value)} onKeyUp={e => setValue('claimAge', e.target.value)}
              />
              <InvalidFeedback error={errors.claimAge} />
            </Form.Group>

            <Form.Group as={Col} xs={12} md={3} lg={3} xl={2} controlId={`formCurrentEarnings`}>
              <Form.Label>Current Earnings</Form.Label>
              <NumberFormatControl className="currentEarningsField" numberStyle="currency" placeholder="$0" value={fields.currentEarnings} isInvalid={!!errors.currentEarnings}
                onChange={e => setValue('currentEarnings', e.target.value)} onKeyUp={e => setValue('currentEarnings', e.target.value)}
              />
              <InvalidFeedback error={errors.currentEarnings} />
            </Form.Group>
          </Form.Row>
        </div>

        <hr />

        <Row style={{justifyContent: 'flex-end'}}>
          <Col xs={5} sm={4} md={3} lg={2}>
            <Button type="submit" className="nextButton" style={{width: '100%'}}>Next</Button>
          </Col>
        </Row>

      </Col>
    </Row>
  </Form>;
}