import React from 'react';
import { Router, Location } from '@reach/router'
import { Container } from 'react-bootstrap';

import { Provider } from 'react-redux';
import store from '../../store';

import '../../styles/rise.scss';

import Score from './Score';
import ScoreSummary from './ScoreSummary';
import NotFound from '../NotFound';

const AppContainer = ({ children }) => {
  return (
    <Container id="app" fluid style={{paddingBottom: '1rem'}}>
      {children}
    </Container>
  );
}

const Analytics = () => {
  const AnalyticsWorker = ({ location }) => {
    if (window.gtag) window.gtag('event', 'page_view', { 'page_path': location.pathname + location.search });
    return null;
  };

  return <Location>
    {({ location }) => <AnalyticsWorker location={location} />}
  </Location>;
}

const App = () => {
  return (
    <Provider store={store}>
      <AppContainer>
        <Router primary={false}>
          <Score path="/" />
          <ScoreSummary path="/score/:scoreId" />
          <NotFound default />
        </Router>
        <Analytics />
      </AppContainer>
    </Provider>
  );
}

export default App;
