import React from 'react';
import { Link } from '@reach/router';
import { Col } from 'react-bootstrap';
import { formatValue } from '../../helpers';
import constants from '../../constants';

import LongevityIcon from '../../images/longevity.svg';
import SavingsIcon from '../../images/savings.svg';
import InvestmentIcon from '../../images/investment.svg';
//import SocialSecurityIcon from '../../images/socialsecurity.svg';
import SpendingIcon from '../../images/spending.svg';
import BehaviorIcon from '../../images/behavior.svg';

const RetirementAgeTip = ({ tip, hasSecondary }) => {
  return <>
    <img src={LongevityIcon} style={{width: '25%'}} className="my-3" alt="" />
    <h6>Delay Retirement</h6>
    <hr />
    <p className="small">
      <>Delaying {hasSecondary ? 'you and your spouse\'s' : 'your'} retirement by as little as </>
      <strong>{tip.adjustment} years</strong>
      <> could help you save more, rely less on your savings, and improve your score by </>
      <strong>{tip.improvement} points</strong>.
    </p>
    <Link to="/simulator/age">Learn more</Link>
  </>;
};

const IncreaseContributionsTip = ({ tip, hasSecondary }) => {
  return <>
    <img src={SavingsIcon} style={{width: '25%'}} className="my-3" alt="" />
    <h6>Increase Contributions</h6>
    <hr />
    <p className="small">
      <>Contributing an additional </>
      <strong>{formatValue(tip.adjustment, 'currency')}/month</strong>
      <> could go a long way to growing {hasSecondary ? 'you and your spouse\'s' : 'your'} retirement nest egg, and improve your score by </>
      <strong>{tip.improvement} points</strong>.
    </p>
    <Link to="/simulator/contributions">Learn more</Link>
  </>;
};

const ChangeAllocationsTip = ({ tip, hasSecondary }) => {
  return <>
    <img src={InvestmentIcon} style={{width: '25%'}} className="my-3" alt="" />
    <h6>Adjust Asset Allocations</h6>
    <hr />
    <p className="small">
      <>Adjusting your current asset allocations to a </>
      <strong>{constants.preallocations[tip.adjustment - 1].label}</strong>
      <> profile could help {hasSecondary ? 'you and your spouse\'s' : 'your'} savings grow, increasing your score by </>
      <strong>{tip.improvement} points</strong>.
    </p>
    <Link to="/simulator/allocations">Learn more</Link>
  </>;
};

const IncreaseIncomeTip = ({ tip, hasSecondary }) => {
  return <>
    <img src={SpendingIcon} style={{width: '25%'}} className="my-3" alt="" />
    <h6>Additional Income</h6>
    <hr />
    <p className="small">
      <>Earning an additional </>
      <strong>{formatValue(tip.adjustment, 'currency')}/month</strong>
      <>{hasSecondary ? ' each,' : ','} working part-time in retirement could help keep {hasSecondary ? 'you and your spouse' : 'you'} busy, while covering a portion of your expenses, increasing your score by </>
      <strong>{tip.improvement} points</strong>.
    </p>
    <Link to="/simulator/income">Learn more</Link>
  </>;
};

const ReduceExpensesTip = ({ tip, hasSecondary }) => {
  return <>
    <img src={BehaviorIcon} style={{width: '25%'}} className="my-3" alt="" />
    <h6>Reduce Expenses</h6>
    <hr />
    <p className="small">
      <>Living a little frugally and reducing your expected retirement expenses by </>
      <strong>{formatValue(tip.adjustment, 'currency')}/month</strong>
      <> will help your income and savings go a long way, increasing your score by </>
      <strong>{tip.improvement} points</strong>.
    </p>
    <Link to="/simulator/expenses">Learn more</Link>
  </>;
};

const AddAnnuityTip = ({ tip, hasSecondary }) => {
  return <>
    <img src={InvestmentIcon} style={{width: '25%'}} className="my-3" alt="" />
    <h6>Add an Annuity</h6>
    <hr />
    <p className="small">
      <>Using </>
      <strong>{formatValue(tip.adjustment)}%</strong>
      <> of your current savings to purchase an annuity will provide {hasSecondary ? 'you and your spouse' : 'you'} with some additional guaranteed income, and improve your score by </>
      <strong>{tip.improvement} points</strong>.
    </p>
    <Link to="/simulator/annuities">Learn more</Link>
  </>;
};

// <Col xs={12} sm={6} md={3} style={{textAlign: 'center'}} className="p-3">

//   <img src={SocialSecurityIcon} style={{width: '25%'}} className="my-3" alt="" />
//   <h6>Defer Social Security</h6>
//   <hr />
//   <p className="small">Deferring your social security benefit by a few years could see your score improve by an additional <strong>29 points</strong>.</p>
//   <a href="/simulator">Learn more</a>

// </Col>

const Tip = ({ tip, tipCount, hasSecondary }) => {
  return <Col xs={12} sm={6} md={tipCount <= 2 ? 6 : (tipCount === 3 ? 4 : 3)} style={{textAlign: 'center'}} className="p-3">
    {tip.type === 'RetirementAge' && <RetirementAgeTip tip={tip} hasSecondary={hasSecondary} />}
    {tip.type === 'Contributions' && <IncreaseContributionsTip tip={tip} hasSecondary={hasSecondary} />}
    {tip.type === 'Allocations' && <ChangeAllocationsTip tip={tip} hasSecondary={hasSecondary} />}
    {tip.type === 'Income' && <IncreaseIncomeTip tip={tip} hasSecondary={hasSecondary} />}
    {tip.type === 'Expenses' && <ReduceExpensesTip tip={tip} hasSecondary={hasSecondary} />}
    {tip.type === 'Annuity' && <AddAnnuityTip tip={tip} hasSecondary={hasSecondary} />}
  </Col>;
};

export {
  RetirementAgeTip,
  IncreaseContributionsTip,
  ChangeAllocationsTip,
  IncreaseIncomeTip,
  ReduceExpensesTip,
  AddAnnuityTip,
  Tip
};