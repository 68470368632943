import React, { useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import IncomeChart from './IncomeChart'

export default function ResultsView({ value, onBack }) {
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <Row>
      <Col className="p-4">

        <div>
          <h2>Social Security Benefit Calculator</h2>
          <p>Your social security benefit is calculated from the highest 35 years of earnings, represented in the colored bars in the chart below.</p>
        </div>

        <hr />

        {value.salaries && value.salaries.length > 0 && <>
          <div>
            <h3>Estimated benefit</h3>

            <Row>
              <Col xs={12} sm="auto" style={{paddingRight: 20}}>
                <p>
                  <span className="benefitAtRetirementMonthlyLabel display-3">${value.benefitAmount.toLocaleString()}</span>
                  <span className="display-4"> / monthly</span>
                  <br />
                  <span className="benefitTodayMonthlyLabel">(${value.benefitAmountDiscounted.toLocaleString()} in today's dollars)</span>
                </p>
              </Col>
              <Col xs={12} sm="auto">
                <p>
                  <span className="benefitAtRetirementAnnuallyLabel display-3">${(value.benefitAmount * 12).toLocaleString()}</span>
                  <span className="display-4"> / annually</span>
                  <br />
                  <span className="benefitTodayAnnuallyLabel">(${(value.benefitAmountDiscounted * 12).toLocaleString()} in today's dollars)</span>
                </p>
              </Col>
              <Col xs={12}>
                <IncomeChart currentAge={value.currentAge} data={value.salaries}></IncomeChart>
              </Col>
            </Row>
          </div>
        </>}

        <hr />

        <div>
          <Row>
            <Col xs={5} sm={4} md={3} lg={2}>
              <Button className="backButton" variant="outline-primary" onClick={onBack} style={{width: '100%'}}>Go Back</Button>
            </Col>
          </Row>
        </div>

      </Col>
    </Row>
  );
}