export const ADD_PROFILES = "ADD_PROFILES";
export const ADD_ACCOUNT = "ADD_ACCOUNT";
export const EMAIL_UPDATED = "EMAIL_UPDATED";
export const PASSWORD_CHANGED = "PASSWORD_CHANGED";
export const NOTIFICATIONS_CHANGED = "NOTIFICATIONS_CHANGED";
export const ADD_REPORT_LATEST = "ADD_REPORT_LATEST";
export const ADD_REPORT = "ADD_REPORT";
export const ADD_REPORTS = "ADD_REPORTS";
export const SET_INITIALIZED = "SET_INITIALIZED";
export const SET_ISLOGGEDIN = "SET_ISLOGGEDIN";
export const RESET = "RESET";