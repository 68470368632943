import React, { useState, useEffect } from 'react';
import { Link } from '@reach/router';
import { connect } from 'react-redux';
import { ADD_PROFILES } from '../store/actionTypes';
import { Nav } from 'react-bootstrap';

import Profiles from './Profile/Profiles';
import SavingsContributions from './Profile/SavingsContributions';
import Allocations from './Profile/Allocations';
import Income from './Profile/Income';
import Expenses from './Profile/Expenses';

import api from '../services/api';
import validators from '../services/validators';

const ProfileWrapperInner = ({ child, profile, dispatch }) => {
  var childAttrs = {};
  if (child === Profiles) childAttrs = { key: 'profiles', validatorMethod: 'validateProfiles', apiMethod: 'saveProfileProfiles' };
  if (child === SavingsContributions) childAttrs = { key: 'savings', validatorMethod: 'validateSavings', apiMethod: 'saveProfileSavings' };
  if (child === Allocations) childAttrs = { key: 'allocations', validatorMethod: 'validateAllocations', apiMethod: 'saveProfileAllocations' };
  if (child === Income) childAttrs = { key: 'income', validatorMethod: 'validateIncome', apiMethod: 'saveProfileIncome' };
  if (child === Expenses) childAttrs = { key: 'expenses', validatorMethod: 'validateExpenses', apiMethod: 'saveProfileExpenses' };
  let { key, validatorMethod, apiMethod } = childAttrs;

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [fields, setFields] = useState(profile[key]);
  const [hasSecondary, setHasSecondary] = useState(profile && profile.profiles && profile.profiles.hasSecondary ? true : false);

  useEffect(() => {
    setFields(profile[key]);
    setHasSecondary(profile && profile.profiles && profile.profiles.hasSecondary ? true : false)
  }, [profile, key]);

  useEffect(() => {
    setLoading(true);
    api.getProfile()
      .then(r => {
        dispatch({ type: ADD_PROFILES, profiles: r.data });
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
      });
  }, [dispatch, setLoading]);

  function handleSave() {
    var e = validators[validatorMethod](fields, hasSecondary);
    setErrors(e);

    if (validators.isValid(e)) {
      setLoading(true);
      api[apiMethod](fields)
        .then(r => {
          dispatch({ type: ADD_PROFILES, profiles: r.data });
          setLoading(false);
        })
        .catch(e => {
          e.status === 400 && setErrors(e.errors);
          setLoading(false);
        });
    }
  }

  return <>
    {child({
      value: fields,
      errors: errors,
      loading: loading,
      primaryRetirementAge: (profile && profile.profiles && profile.profiles.primary && parseInt(profile.profiles.primary.retirementAge)) || null,
      secondaryRetirementAge: (profile && profile.profiles && profile.profiles.secondary && parseInt(profile.profiles.secondary.retirementAge)) || null,
      hasSecondary: hasSecondary,
      onChange: v => setFields(v),
      onSubmit: () => handleSave()
    })}
  </>;
};

export const ProfileWrapper = connect(state => ({profile: state.profile}))(ProfileWrapperInner);

export const Profile = ({ children, ...props }) => {
  const tab = props['*'];

  return <>
    <Nav justify variant="pills" activeKey={tab} className="mx-3 mb-3 nav-underline">
      <Nav.Item>
        <Nav.Link as={Link} to="/profile/about" eventKey="about" state={{dontScroll: true}}>Personal Details</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to="/profile/savings" eventKey="savings" state={{dontScroll: true}}>Savings &amp; Contributions</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to="/profile/allocations" eventKey="allocations" state={{dontScroll: true}}>Allocations</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to="/profile/income" eventKey="income" state={{dontScroll: true}}>Income in Retirement</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to="/profile/expenses" eventKey="expenses" state={{dontScroll: true}}>Expenses in Retirement</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to="/profile/account" eventKey="account" state={{dontScroll: true}}>Sign In &amp; Notifications</Nav.Link>
      </Nav.Item>
    </Nav>

    {children}
  </>;
}
