import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { NumberFormatControl, SubmitButtons } from './Shared';
import { InvalidFeedback } from '../Shared/Helpers';
import { SwappingRule } from '../Shared/Layout';

const increments = [
  { amount: 2500, count: 8 },
  { amount: 5000, count: 16 },
  { amount: 10000, count: 10 },
  { amount: 25000, count: 12 },
  { amount: 50000, count: 20 },
  { amount: 100000, count: 15 },
  { amount: 250000, count: 8 }
];

const calculatedIncrements = (() => {
  const calculated = [0];
  for (var i = 0; i < increments.length; ++i) {
    const inc = increments[i];
    for (var j = 0; j < inc.count; ++j)
      calculated.push(calculated[calculated.length - 1] + inc.amount);
  }
  return calculated;
})();

const closestIncrementValue = (value) => {
  var v = parseInt(value) || 0;
  var lastDifference = v;

  for (var i = 0; i < calculatedIncrements.length; ++i) {
    var inc = calculatedIncrements[i];
    var diff = Math.abs(v - inc);
    if (diff > lastDifference)
      return i - 1;
    lastDifference = diff;
  }
  return calculatedIncrements.length - 1;
};

const Blurb = () => {
  return <p>
    Tell us about your savings. Make sure to include all types of assets, as these assets will be drawn on in retirement if required
    (any sources of retirement income that you have will be used first in attempting to meet your retirement spending needs).
    We will assume that your contributions will end when you retire.
  </p>
}

// const LinkAccounts = ({ loading, onClick }) => {
//   return <>
//     <hr />
//     <p>Link your investment accounts so you don't have to manually keep this section up to date.</p>
//     <Button variant="outline-primary" disabled={loading}>Link Accounts</Button>
//   </>;
// }

export default function SavingsContributions({ value, errors, loading, hasSecondary = false, submitLabel, cancelLabel, showCancel, buttonAlignment, onChange, onSubmit, onCancel }) {
  value =                         value || {};
  value.savings =                 value.savings === null || value.savings === undefined ? '' : value.savings;
  value.primaryContributions =    value.primaryContributions === null || value.primaryContributions === undefined ? '' : value.primaryContributions;
  value.secondaryContributions =  value.secondaryContributions === null || value.secondaryContributions === undefined ? '' : value.secondaryContributions;

  errors = errors || {};

  const calculatedIncrementValue = closestIncrementValue(value.savings);

  function setValue(name, v) {
    value[name] = v;
    onChange({ ...value });
  }

  return <>
    <Row>
      <Col lg={8} className="p-3">
        <Form noValidate autoComplete="off" onSubmit={(e) => { e.preventDefault(); onSubmit(); return false; }}>
          <div className="d-lg-none mb-3">
            <Blurb />
            <hr />
          </div>

          <h5 className="text-uppercase">Savings</h5>
            <p>How much do you {hasSecondary && 'and your spouse'} have saved across all of your savings and retirement accounts?</p>
          
            <Form.Group controlId="formSavings">
              <Form.Label>Total Amount of Savings</Form.Label>
              <Form.Row className="mt-4 mb-2">
                <Col xs="1" className="text-right"><small>$0</small></Col>
                <Col>
                  <input type="range" className="custom-range" min={0} value={calculatedIncrementValue} max={calculatedIncrements.length - 1} onChange={e => setValue('savings', `${calculatedIncrements[e.target.value]}`)} disabled={loading} />
                </Col>
                <Col xs="1"><small>$5M+</small></Col>
              </Form.Row>
              <Form.Row className="justify-content-center mb-5">
                <Col xs={4}>
                  <NumberFormatControl value={value.savings} numberStyle="currency" isInvalid={!!errors.savings} onChange={e => setValue('savings', e.target.value)} disabled={loading} />
                  <InvalidFeedback error={errors.savings} />
                </Col>
              </Form.Row>
            </Form.Group>

            {/* <div className="text-center d-lg-none">
              <LinkAccounts loading={loading} />
            </div> */}

            <hr />
            
            <h5 className="text-uppercase">Contributions</h5>
            <p>How much do you {hasSecondary && 'and your spouse'} plan to contribute to your savings until retirement?</p>

            <Form.Row>
              <Form.Group as={Col} xs={6} controlId="formPrimaryContributions">
                <Form.Label>{hasSecondary && 'Your'} Contributions</Form.Label>
                <NumberFormatControl value={value.primaryContributions} numberStyle="currency" append="/ month" onChange={e => setValue('primaryContributions', e.target.value)} isInvalid={!!errors.primaryContributions} disabled={loading} />
                <InvalidFeedback error={errors.primaryContributions} />
              </Form.Group>
              {hasSecondary && 
                <Form.Group as={Col} xs={6} controlId="formSecondaryContributions">
                  <Form.Label>Your Spouse's Contributions</Form.Label>
                  <NumberFormatControl value={value.secondaryContributions} numberStyle="currency" append="/ month" onChange={e => setValue('secondaryContributions', e.target.value)} isInvalid={!!errors.secondaryContributions} disabled={loading} />
                  <InvalidFeedback error={errors.secondaryContributions} />
                </Form.Group>
              }
            </Form.Row>


            <hr />

            <SubmitButtons {...{loading, submitLabel, cancelLabel, showCancel, buttonAlignment, onSubmit, onCancel}} />
          </Form>
      </Col>

      <SwappingRule breakpoint="sm" />

      <Col lg={4} className="p-3 d-none d-lg-block">

        <div>
          <Blurb />
        </div>

        {/* <div className="mb-3">
          <LinkAccounts loading={loading} />
        </div> */}

      </Col>

    </Row>
  
  </>
}