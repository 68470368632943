import React, { useState } from 'react';
import InputsView from './SocialSecurity/InputsView';
import EarningsInputView from './SocialSecurity/EarningsInputView';
import ResultsView from './SocialSecurity/ResultsView';
import EarningsService from '../services/socialsecurity';
import api from '../services/api';

export default function App() {
  const [inputs, setInputs] = useState(null);
  const [screen, setScreen] = useState('inputs');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [results, setResults] = useState(null);

  const submit = (fields) => {
    setLoading(true);
    setError(false);
    
    const earnings = EarningsService.project(fields.currentAge, fields.retirementAge, fields.claimAge, fields.earnings);
    const salaries = {};
    earnings.forEach(e => salaries[e.age] = e.amount);

    api.calculateSocialSecurityBenefit({
      currentAge: parseInt(fields.currentAge),
      retirementAge: parseInt(fields.retirementAge),
      claimingAge: parseInt(fields.claimAge),
      salaries,
      benefitFrequency: 'Monthly'
    })
    .then(r => {
      setLoading(false);
      setError(false);
      setResults(r.data);
      setScreen('results');
    })
    .catch(e => {
      setLoading(false);
      setError(true);
    });    
  }

  switch (screen) {
    case 'inputs':
      return <InputsView value={inputs} onNext={values => { setInputs({...inputs, ...values}); setScreen('earnings'); }} />;
    case 'earnings':
      return <EarningsInputView value={inputs} loading={loading} error={error} onBack={() => { setScreen('inputs')}} onNext={values => { var newInputs = {...inputs, ...values}; setInputs(newInputs); submit(newInputs); }} />;
    case 'results':
      return <ResultsView value={{...results, currentAge: inputs.currentAge}} onBack={() => { setScreen('earnings'); }} />;
    default:
      return null;
  }
}
