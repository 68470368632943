import React, { useState } from 'react';
import { Row, Col, Nav } from 'react-bootstrap';
import { ResponsiveContainer, Tooltip, XAxis, AreaChart, Area, BarChart, Bar, ReferenceLine, LabelList, YAxis, CartesianGrid } from 'recharts';
import { formatValue } from '../../helpers';
import constants from '../../constants';
import moment from 'moment';

const getTicks = (minYear, maxYear, step = 5) => {
  const years = [];
  let current = Math.ceil(minYear / step) * step;
  while (current <= maxYear) {
    years.push(current);
    current += step;
  }
  return years;
}

const TooltipWithAges = ({ active, payload, label, primary, secondary }) => {
  if (active) {
    return (
      <div className="recharts-default-tooltip" style={{
        margin: 0,
        padding: 10,
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        whiteSpace: 'nowrap'
      }}>
        <strong>{label}</strong>
        {((payload[0] && payload[0].payload.ageCount && payload[0].payload.ages) || []).map((n, i) => <p key={i} className="mb-0">{n}</p>)}

        {payload.map((p, i) => 
          <p key={i} className="label mb-0" style={{color: p.stroke || p.fill}}>
            {constants.reportScenarioChartLabels[p.name](primary, secondary)}: {formatValue(p.value, 'currency')}
          </p>
        )}
      </div>
    );
  }

  return null;
};

const AgeLabel = ({ x, y, width, height, value }) => {
  if (value)
    return <text x={x + width / 2} y={y + height - 4} fill="#fff" textAnchor="middle">&bull;</text>;
  return <></>;
}

const ApplyAgeSeries = (item, year, primary, secondary) => {
  if (year === primary.retirementYear) { item.ages = item.ages || []; item.ages.push(`${primary.name || 'You'} Retire${primary.name ? 's' : ''} at ${primary.retirementYear - primary.yearOfBirth - 1}`); }
  if (year === primary.deathYear) { item.ages = item.ages || []; item.ages.push(`${primary.name || 'You'} Pass${primary.name ? 'es' : ''} at ${primary.deathYear - primary.yearOfBirth - 1}`); }
  if (secondary && year === secondary.retirementYear) { item.ages = item.ages || []; item.ages.push(`${secondary.name || 'Your Spouse'} Retires at ${secondary.retirementYear - secondary.yearOfBirth - 1}`); }
  if (secondary && year === secondary.deathYear) { item.ages = item.ages || []; item.ages.push(`${secondary.name || 'Your Spouse'} Passes at ${secondary.deathYear - secondary.yearOfBirth - 1}`); }
  if (item.ages) item.ageCount = item.ages.length;
}

const GetProfile = (report, scenario, profile) => {
  let ageIndex = scenario.data[`${profile}Age`].findIndex(a => a === null) - 1;
  if (ageIndex < 0) ageIndex = scenario.data[`${profile}Age`].length - 1;
  return {
    name: report[`${profile}Name`],
    yearOfBirth: report[`${profile}YearOfBirth`],
    deathYear: report[`${profile}YearOfBirth`] + scenario.data[`${profile}Age`][ageIndex] + 1,
    retirementYear: report[`${profile}YearOfBirth`] + report[`${profile}RetirementAge`] + 1
  };
}

const MapPortfolioData = (data, startYear, primary, secondary) => {
  return data.year.map(y => {
    const year = startYear + y;
    const item = {
      year: year,
      accountValue: data.accounts[0].accountValue[y],
      withdrawals: data.withdrawals[y]
    };

    if (data.accounts[0].contributions[0])
      item.primaryContributions = data.accounts[0].contributions[0].values[y];

    if (secondary && data.accounts[0].contributions[1])
      item.secondaryContributions = data.accounts[0].contributions[1].values[y];

    ApplyAgeSeries(item, year, primary, secondary);

    return item;
  });
}

const MapCashFlowData = (data, startYear, primary, secondary) => {
  return data.year.map(y => {
    const year = startYear + y;
    const item = {
      year: year,
      withdrawals: data.withdrawals[y]
    };

    data.income.forEach(c => {
      if (!c.ref.startsWith('Secondary') || (secondary && c.ref.startsWith('Secondary')))
        item[c.ref.substr(0, 1).toLowerCase() + c.ref.substr(1)] = c.values[y]
    });

    data.expenses.forEach(c => {
      if (!c.ref.startsWith('Secondary') || (secondary && c.ref.startsWith('Secondary')))
        item[c.ref.substr(0, 1).toLowerCase() + c.ref.substr(1)] = c.values[y] !== null ? -c.values[y] : null
    });

    ApplyAgeSeries(item, year, primary, secondary);

    return item;
  })
  .filter(i => Object.keys(i).filter(k => k !== 'year').some(k => i[k] !== null));
}

const ReportScenario = ({ report, scenario, startYear }) => {
  const hasSecondary = report.secondaryYearOfBirth && report.secondaryRetirementAge ? true : false;
  const primaryProfile = GetProfile(report, scenario, 'primary');
  const secondaryProfile = hasSecondary ? GetProfile(report, scenario, 'secondary') : null;
  const portfolioData = MapPortfolioData(scenario.data, startYear, primaryProfile, secondaryProfile);
  const cashflowData = MapCashFlowData(scenario.data, startYear, primaryProfile, secondaryProfile);
  
  const incomeSeries = [
      'withdrawals', 'primarySocialSecurity', 'primaryPension', 'primaryAnnuity', 'primaryOtherIncome',
      'secondarySocialSecurity', 'secondaryPension', 'secondaryAnnuity', 'secondaryOtherIncome'
    ].filter(k => cashflowData.some(c => Object.keys(c).indexOf(k) >= 0));

  const expenseSeries = [
      'jointBasicLivingExpenses', 'primaryBasicLivingExpenses', 'secondaryBasicLivingExpenses',
      'primaryMedicalExpenses', 'secondaryMedicalExpenses'
    ].filter(k => cashflowData.some(c => Object.keys(c).indexOf(k) >= 0));

  return <>
    <h5 className="text-uppercase">{constants.reportScenarioHeadings[scenario.type]}</h5>
    <p>{constants.reportScenarioDescriptions[scenario.type]}</p>

    <h6>Portfolio &amp; Contributions</h6>
    <p>The following illustrates your entire retirement portfolio over time, both pre and post-retirement, including contributions to your account during the pre-retirement phase.</p>
  
    <ResponsiveContainer maxHeight={360}>
      <AreaChart data={portfolioData} margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
        <CartesianGrid vertical={false} stroke="#dddddd" />
        <XAxis dataKey="year" tick={{fontSize: 11, fontFamily: 'sans-serif'}} ticks={getTicks(portfolioData[0].year, portfolioData[portfolioData.length - 1].year)} />
        <YAxis scale="linear" tick={{fontSize: 11, fontFamily: 'sans-serif'}} axisLine={false} tickFormatter={t => `${formatValue(t / 1000, 'currency')}k`} />
        <Tooltip content={<TooltipWithAges primary={primaryProfile} secondary={secondaryProfile} />} />
        <Area dataKey="accountValue"              stroke="rgb(232, 193, 160)" strokeWidth={2} fill="rgba(232, 193, 160, 0.2)" isAnimationActive={false} />
        <Area dataKey="primaryContributions"      stroke="rgb(244, 117, 96)"  strokeWidth={2} fill="rgba(244, 117, 96, 0.2)"  isAnimationActive={false} />
        <Area dataKey="secondaryContributions"    stroke="rgb(244, 117, 96)"  strokeWidth={2} fill="rgba(244, 117, 96, 0.2)"  isAnimationActive={false} />
        <Area dataKey="withdrawals"               stroke="rgb(244, 117, 96)"  strokeWidth={2} fill="rgba(244, 117, 96, 0.2)"  isAnimationActive={false} />
        
        <ReferenceLine x={primaryProfile.retirementYear}  stroke="rgb(176, 65, 62)" strokeWidth={2} />
        <ReferenceLine x={primaryProfile.deathYear}       stroke="rgb(176, 65, 62)" strokeWidth={2} />

        {hasSecondary && <ReferenceLine x={secondaryProfile.retirementYear} stroke="rgb(176, 65, 62)" strokeWidth={2} />}
        {hasSecondary && <ReferenceLine x={secondaryProfile.deathYear}      stroke="rgb(176, 65, 62)" strokeWidth={2} />}
      </AreaChart>
    </ResponsiveContainer>

    <h6>Income &amp; Expenses</h6>
    <p>The following illustrates all sources of income and expenses while in retirement.</p>

    <ResponsiveContainer maxHeight={360}>
      <BarChart data={cashflowData} margin={{ top: 5, right: 5, left: 5, bottom: 5 }} stackOffset="sign">
        <CartesianGrid vertical={false} stroke="#dddddd" />
        <XAxis dataKey="year" tick={{fontSize: 11, fontFamily: 'sans-serif'}} ticks={getTicks(cashflowData[0].year, cashflowData[cashflowData.length - 1].year)} />
        <YAxis scale="linear" tick={{fontSize: 11, fontFamily: 'sans-serif'}} axisLine={false} tickFormatter={t => `${formatValue(t / 1000, 'currency')}k` } />
        <Tooltip content={<TooltipWithAges primary={primaryProfile} secondary={secondaryProfile} />} />

        {incomeSeries.map((s, i) =>
          <Bar key={`i${i}`} dataKey={s} stackId="0" isAnimationActive={false}
            fill={constants.incomeColors[i % constants.incomeColors.length]}
            stroke={constants.incomeColors[i % constants.incomeColors.length]}
          >
            {i === 0 && <LabelList dataKey="ageCount" content={AgeLabel} />}
          </Bar>
        )}

        {expenseSeries.map((s, i) =>
          <Bar key={`e${i}`} dataKey={s} stackId="0" isAnimationActive={false}
            fill={constants.expenseColors[i % constants.expenseColors.length]}
            stroke={constants.expenseColors[i % constants.expenseColors.length]}
          />
        )}

        <ReferenceLine y={0} stroke="rgb(0, 0, 0)" />
      </BarChart>
    </ResponsiveContainer>
  </>;
};

const ReportScenarios = ({ report }) => {  
  const [activeScenario, setActiveScenario] = useState(0);

  return <>
    <h5 className="text-uppercase mt-3 mx-3 mb-0">Hand Picked Scenarios</h5>

    <Row>
      <Col sm={4} className="p-3">
        <Nav variant="pills" activeKey={`scenario-${activeScenario}`} className="flex-column nav-underline">
          {report.scenarios.map((s, i) =>
            <Nav.Link key={i} eventKey={`scenario-${i}`} onClick={() => setActiveScenario(i)}>
              {constants.reportScenarioTypes[s.type]}
            </Nav.Link>
          )}
        </Nav>
      </Col>

      {report.scenarios[activeScenario] &&
        <Col sm={8} className="p-3">
          <ReportScenario report={report} scenario={report.scenarios[activeScenario]} startYear={moment(report.created).year()} />
        </Col>
      }
    </Row>
  </>;
};

export default ReportScenarios;
