import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { SwappingRule } from '../Shared/Layout';
import constants from '../../constants';
import { formatValue } from '../../helpers';

const ReportInputs = ({ report }) => {
  const hasSecondary = report.secondaryYearOfBirth && report.secondaryRetirementAge ? true : false;

  function HasGroup(participants, items) {
    for (var i = 0; i < participants.length; ++i) {
      var allItems = true;

      for (var j = 0; j < items.length; ++j) {
        if (!report[`${participants[i]}${items[j]}`])
          allItems = false;
      }

      if (allItems)
        return true;
    }
    return false;
  }

  function IncomeItem(participant, item) {
    if (report[`${participant}${item}Amount`] && report[`${participant}${item}CommencementAge`])
      return <>
        {formatValue(report[`${participant}${item}Amount`], 'currency')}/month
        {hasSecondary ? ` (${report[`${participant}Name`] || (participant === 'primary' ? 'You' : 'Your Spouse')})` : ''}
        <br />
      </>;
    return <></>;
  }

  function ExpenseItem(participant, item) {
    if (report[`${participant}${item}`]) {
      var participantLabel = '';

      if (hasSecondary) {
        if (participant === 'joint')
          participantLabel = 'Combined';
        else
          participantLabel = report[`${participant}Name`] || (participant === 'primary' ? 'You' : 'Your Spouse');
      }

      return <>
        {formatValue(report[`${participant}${item}`], 'currency')}/month
        {participantLabel ? ` (${participantLabel})` : ''}<br />
      </>;
    }
    return <></>;
  }

  return <Row>
    <Col xs={12} sm={6} md={3} className="p-3">
      <h6>Participants</h6>
      <p>
        <strong>{report.primaryName || 'You'}</strong><br />
        {report.primaryGender} {new Date().getUTCFullYear() - report.primaryYearOfBirth} {report.primaryState}<br />
        {report.primaryHealth && <>
          {constants.healthLevels[report.primaryHealth]} Health<br />
        </>}
        Retire at {report.primaryRetirementAge}
      </p>

      {hasSecondary && <p>
        <strong>{report.secondaryName || 'Your Spouse'}</strong><br />
        {report.secondaryGender} {new Date().getUTCFullYear() - report.secondaryYearOfBirth} {report.secondaryState}<br />
        {report.secondaryHealth && <>
          {constants.healthLevels[report.secondaryHealth]} Health<br />
        </>}
        Retire at {report.secondaryRetirementAge}
      </p>}
    </Col>
    
    <SwappingRule breakpoint="sm" />
    
    <Col xs={12} sm={6} md={3} className="p-3">
      <h6>Savings</h6>
      <p>
        {formatValue(report.savings || 0, 'currency')} in Total Savings
      </p>
      
      <h6>Contributions</h6>
      <p className="mb-0">
        {formatValue(report.primaryContributions || 0, 'currency')}/month {hasSecondary ? `(${report.primaryName || 'You'})` : ''}
        {hasSecondary && <>
          <br />
          {formatValue(report.secondaryContributions || 0, 'currency')}/month ({report.secondaryName || 'Your Spouse'})
        </>}
      </p>
    </Col>
    
    <SwappingRule breakpoint="md" />
    
    <Col xs={12} sm={6} md={3} className="p-3">
      <h6>Income in Retirement</h6>
      
      {HasGroup(['primary', 'secondary'], ['SocialSecurityAmount', 'SocialSecurityCommencementAge']) &&
        <p>
          <strong>Social Security</strong><br />
          {IncomeItem('primary', 'SocialSecurity')}
          {IncomeItem('secondary', 'SocialSecurity')}
        </p>
      }

      {HasGroup(['primary', 'secondary'], ['PensionAmount', 'PensionCommencementAge']) &&
        <p>
          <strong>Pension</strong><br />
          {IncomeItem('primary', 'Pension')}
          {IncomeItem('secondary', 'Pension')}
        </p>
      }

      {HasGroup(['primary', 'secondary'], ['AnnuityAmount', 'AnnuityCommencementAge']) &&
        <p>
          <strong>Annuity</strong><br />
          {IncomeItem('primary', 'Annuity')}
          {IncomeItem('secondary', 'Annuity')}
        </p>
      }

      {HasGroup(['primary', 'secondary'], ['OtherIncomeAmount', 'OtherIncomeCommencementAge']) &&
        <p>
          <strong>Other Income</strong><br />
          {IncomeItem('primary', 'OtherIncome')}
          {IncomeItem('secondary', 'OtherIncome')}
        </p>
      }      
    </Col>
    
    <SwappingRule breakpoint="sm" />
    
    <Col xs={12} sm={6} md={3} className="p-3">
      <h6>Expenses in Retirement</h6>
      {HasGroup(['joint', 'primary', 'secondary'], ['BasicLivingExpenses']) &&
        <p>
          <strong>Basic Living Expenses</strong><br />
          {ExpenseItem('joint', 'BasicLivingExpenses', '')}
          {ExpenseItem('primary', 'BasicLivingExpenses', '')}
          {ExpenseItem('secondary', 'BasicLivingExpenses', '')}
        </p>
      }

      {HasGroup(['primary', 'secondary'], ['MedicalExpenses']) &&
        <p>
          <strong>Medical Expenses</strong><br />
          {ExpenseItem('primary', 'MedicalExpenses', 'Medical Expenses')}
          {ExpenseItem('secondary', 'MedicalExpenses', 'Medical Expenses')}
        </p>
      }
    </Col>
  </Row>
};

export default ReportInputs;