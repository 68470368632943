import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import ScoreTiles from './ScoreTiles';
import api from '../../services/api';
import { formatValue } from '../../helpers';

const increments = [
  { amount: 50, count: 8 },
  { amount: 100, count: 16 },
  { amount: 250, count: 12 },
  { amount: 500, count: 10 },
  { amount: 1000, count: 10 }
];

const calculatedIncrements = (() => {
  const calculated = [0];
  for (var i = 0; i < increments.length; ++i) {
    const inc = increments[i];
    for (var j = 0; j < inc.count; ++j)
      calculated.push(calculated[calculated.length - 1] + inc.amount);
  }
  return calculated;
})();

const closestIncrementValue = (value) => {
  var v = parseInt(value) || 0;
  var lastDifference = v;

  for (var i = 0; i < calculatedIncrements.length; ++i) {
    var inc = calculatedIncrements[i];
    var diff = Math.abs(v - inc);
    if (diff > lastDifference)
      return i - 1;
    lastDifference = diff;
  }
  return calculatedIncrements.length - 1;
};

const ExpenseSlider = ({ name, isJoint, isSecondary, type, value, disabled, onChange }) => {
  const label = isJoint ? 'Your joint' : (name ? `${name}'s` : (!isSecondary ? 'Your' : 'Your Spouse\'s'));
  
  return <Row className="justify-content-center">
    <Col xs={12} className="text-center py-1">
      <Row>
        <Col>
          <label htmlFor="customRange" className="text-center">{label} {type} of <strong>{formatValue(calculatedIncrements[value], 'currency')}</strong> per month in retirement</label>
          <input type="range" className="custom-range" id="customRange" min={0} max={calculatedIncrements.length - 1}
            disabled={disabled}
            value={value}
            onChange={e => onChange(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6} className="text-left">
          <strong>{formatValue(calculatedIncrements[0], 'currency')}</strong>
        </Col>
        <Col xs={6} className="text-right">
          <strong>{formatValue(calculatedIncrements[calculatedIncrements.length - 1], 'currency')}</strong>
        </Col>
      </Row>
    </Col>
  </Row>;
}

const Expenses = ({ profile }) => {
  const hasSecondary = profile.profiles && profile.profiles.secondary && profile.profiles.secondary.yearOfBirth && profile.profiles.secondary.retirementAge ? true : false;
  const [jointBasicLivingExpenses, setJointBasicLivingExpenses] = useState(closestIncrementValue(profile.expenses.jointBasicLivingExpenses));
  const [primaryBasicLivingExpenses, setPrimaryBasicLivingExpenses] = useState(closestIncrementValue(profile.expenses.primaryBasicLivingExpenses));
  const [secondaryBasicLivingExpenses, setSecondaryBasicLivingExpenses] = useState(closestIncrementValue(profile.expenses.secondaryBasicLivingExpenses));
  const [primaryMedicalExpenses, setPrimaryMedicalExpenses] = useState(closestIncrementValue(profile.expenses.primaryMedicalExpenses));
  const [secondaryMedicalExpenses, setSecondaryMedicalExpenses] = useState(closestIncrementValue(profile.expenses.secondaryMedicalExpenses));
  const timeout = useRef(null);
  const cancelTokenSource = useRef(null);
  const [loading, setLoading] = useState(true);
  const [locked, setLocked] = useState(true);
  const [scores, setScores] = useState(null);

  useEffect(() => {
    setLoading(true);

    if (timeout.current) clearTimeout(timeout.current);
    if (cancelTokenSource.current) cancelTokenSource.current.cancel('Cancelled');
    
    timeout.current = setTimeout(() => {
      setLocked(true);
      let jointBasicLivingExpensesAmount = parseInt(jointBasicLivingExpenses) >= 0 ? calculatedIncrements[parseInt(jointBasicLivingExpenses)] : null;
      let primaryBasicLivingExpensesAmount = parseInt(primaryBasicLivingExpenses) >= 0 ? calculatedIncrements[parseInt(primaryBasicLivingExpenses)] : null;
      let secondaryBasicLivingExpensesAmount = parseInt(secondaryBasicLivingExpenses) >= 0 ? calculatedIncrements[parseInt(secondaryBasicLivingExpenses)] : null;
      let primaryMedicalExpensesAmount = parseInt(primaryMedicalExpenses) >= 0 ? calculatedIncrements[parseInt(primaryMedicalExpenses)] : null;
      let secondaryMedicalExpensesAmount = parseInt(secondaryMedicalExpenses) >= 0 ? calculatedIncrements[parseInt(secondaryMedicalExpenses)] : null;

      cancelTokenSource.current = api.getCancelTokenSource();
      api.simulatorExpenses(jointBasicLivingExpensesAmount, primaryBasicLivingExpensesAmount, secondaryBasicLivingExpensesAmount, primaryMedicalExpensesAmount, secondaryMedicalExpensesAmount, cancelTokenSource.current.token)
        .then(r => {
          setScores(r.data);
          setLoading(false);
          setLocked(false);
        })
        .catch(e => {
          if (!e.cancelled) {
            setLoading(false);
            setLocked(false);
          }
        });
    }, 1000);

    return function cleanup() {
      if (timeout.current) clearTimeout(timeout.current);
      if (cancelTokenSource.current) cancelTokenSource.current.cancel('Cancelled');
    }
  }, [jointBasicLivingExpenses, primaryBasicLivingExpenses, secondaryBasicLivingExpenses, primaryMedicalExpenses, secondaryMedicalExpenses]);

  return <Row>
    <ScoreTiles scores={scores} loading={loading} />

    <Col xs={12} className="p-3">
      <h5 className="text-uppercase">Expenses in Retirement</h5>

      <div className="my-3">
        <ExpenseSlider name={profile.profiles.primary.name} isJoint={hasSecondary} type="basic living expenses"
          value={jointBasicLivingExpenses} onChange={v => setJointBasicLivingExpenses(v)} disabled={locked} />

        {hasSecondary && <ExpenseSlider name={profile.profiles.primary.name} type="basic living expenses"
          value={primaryBasicLivingExpenses} onChange={v => setPrimaryBasicLivingExpenses(v)} disabled={locked} />}

        {hasSecondary && <ExpenseSlider name={profile.profiles.secondary.name} isSecondary={true} type="basic living expenses"
          value={secondaryBasicLivingExpenses} onChange={v => setSecondaryBasicLivingExpenses(v)} disabled={locked} />}
      </div>

      <div className="my-3">
        <ExpenseSlider name={profile.profiles.primary.name} type="medical expenses"
          value={primaryMedicalExpenses} onChange={v => setPrimaryMedicalExpenses(v)} disabled={locked} />

        {hasSecondary && <ExpenseSlider name={profile.profiles.secondary.name} isSecondary={true} type="medical expenses"
          value={secondaryMedicalExpenses} onChange={v => setSecondaryMedicalExpenses(v)} disabled={locked} />}
      </div>

      <Row>
        <Col>
          <p>
            Expenses in retirement may be unexpected.
            At the very least, they are hard to predict.
            You may want to see how living more frugally, or in excess, can affect your RISE Score.
          </p>
        </Col>
      </Row>
    </Col>
  </Row>;
}

export default connect(state => ({
  profile: state.profile
}))(Expenses);