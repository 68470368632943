import { SET_INITIALIZED, RESET } from '../actionTypes';

const initialState = false;

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_INITIALIZED:
      return action.isInitialized;
    case RESET:
      return initialState;
    default:
      return state;
  }
}