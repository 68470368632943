import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Container, Row, Col, Button } from 'react-bootstrap';
import classes from './Disclaimer.module.scss';
import config from '../../config';

export default function Disclaimer() {
  const [needsDisclaimer, setNeedsDisclaimer] = useState(false);
  const [display, setDisplay] = useState(false);
  const [opacity, setOpacity] = useState(0);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    var value = Cookies.get('cookieconsent_status');
    var show = !(value === 'allow');
    setNeedsDisclaimer(show);
    if (show === true) 
      setTrigger(true);
  }, []);

  useEffect(() => {
    if (trigger) {
      if (needsDisclaimer) {
        setDisplay(true);
        setTimeout(() => setOpacity(1), 1);
      }
      else {
        setOpacity(0);
        setTimeout(() => setDisplay(false), 1000);  
      }
      setTrigger(false);
    }
  }, [needsDisclaimer, trigger]);


  function handleAccept() {
    Cookies.set('cookieconsent_status', 'allow', { expires: 365, domain: config.COOKIES_DOMAIN });
    setNeedsDisclaimer(false);
    setTrigger(true);
  }

  function handleCancel() {
    document.location = config.WEB_URL;
  }

  return (
    <div className={classes.consent} style={{display: display ? 'block' : 'none', opacity: opacity}}>
      <Container>
        <Row style={{alignItems: 'center'}}>
          <Col xs={12} md={true} className={`${classes.text} p-3`}>
            <p style={{marginBottom: 0}}>
              This website uses cookies to provide you with an optimized experience. Refer to
              our <a href="https://www.milliman.com/privacy-policy" target="_blank" rel="noopener noreferrer">privacy policy</a> for
              more information regarding our use of data
              or <a href="https://www.milliman.com/privacy-policy" target="_blank" rel="noopener noreferrer">click here</a> to
              learn more about the cookies we use and how to disable them.
            </p>
          </Col>
          <Col xs={6} md="auto" className="px-2" style={{textAlign: 'center', margin: '1rem 0'}}>
            <Button onClick={handleAccept} style={{paddingLeft: 24, paddingRight: 24}}>Accept</Button>
          </Col>
          <Col xs={6} md="auto" className="px-2" style={{textAlign: 'center', margin: '1rem 0'}}>
            <Button variant="outline-primary" onClick={handleCancel} style={{paddingLeft: 24, paddingRight: 24}}>Cancel</Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}