import { SET_ISLOGGEDIN, RESET } from '../actionTypes';

const initialState = false;

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_ISLOGGEDIN:
      return action.isLoggedIn;
    case RESET:
      return initialState;
    default:
      return state;
  }
}