import React from 'react';

export function Box({ className, children, ...props }) {
  var classNames = `px-3 ${className || ''}`;
  return <div {...props} className={classNames}>
    {children}
  </div>;
}

export function VerticalRule({ className, ...props }) {
  var classNames = `vr ${className || ''}`;
  return <div {...props} className={classNames}></div>;
}

export function SwappingRule({ breakpoint }) {
  return <>
    <VerticalRule className={`d-none d-${breakpoint}-block`} />
    <div className={`d-${breakpoint}-none px-3 w-100`}>
      <hr className="my-0" />
    </div>
  </>;
}

export function Gap({ className, ...props }) {
  var classNames = `gap ${className || ''}`;
  return <div {...props} className={classNames}></div>;
}