import React, { useState } from 'react';
import { Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { InvalidFeedback } from './Shared/Helpers';
import { Link } from '@reach/router';
import api from '../services/api';
import validators from '../services/validators';

const ForgotPassword = () => {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  function handleSubmit() {
    const e = validators.validateForgotPassword(fields);
    setErrors(e);

    if (validators.isValid(e)) {
      setLoading(true);
      api.forgotPassword({...fields})
        .then(r => {
          setLoading(false);
          setSuccess(true);
        })
        .catch(e => {
          e.status === 400 && setErrors(e.errors);
          setLoading(false);
        });
    }
  }

  if (success === true) {
    return <>
      <Row className="justify-content-center align-items-center" style={{minHeight: '50vh'}}>
        <Col lg={6} className="p-4 p-lg-5 justify-content-center d-flex align-items-center">
          <div className="w-100">

            <h5 className="text-uppercase mb-3">Reset Password Email Sent</h5>

            <p>Check your email for a link to reset your password. If it doesn't appear within a few minutes, check your spam folder.</p>

            <Button as={Link} to="/login" variant="primary" type="submit" disabled={loading} className="w-100 mb-4">
              Return to log in
            </Button>

          </div>
        </Col>
      </Row>
    </>;
  }

  return <>
    <Row className="justify-content-center align-items-center" style={{minHeight: '50vh'}}>
      <Col lg={6} className="p-4 p-lg-5 justify-content-center d-flex align-items-center">
        <div className="w-100">

          <h5 className="text-uppercase mb-3">Forgot your password?</h5>

          <p>Enter your email address below and we will send you a password reset link.</p>

          <Form noValidate autoComplete="off" onSubmit={(e) => { e.preventDefault(); handleSubmit(); return false; }}>

            {!!errors[''] && <Alert variant="danger">{errors['']}</Alert> }

            <Form.Group as={Col} controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" value={fields.email || ''} onChange={e => setFields({...fields, email: e.target.value})} isInvalid={!!errors.email} disabled={loading} />
              <InvalidFeedback error={errors.email} />
            </Form.Group>

            <Form.Group as={Col} className="mb-4">
              <Button variant="primary" type="submit" disabled={loading} className="w-100">
                Send Password Reset Email
              </Button>
            </Form.Group>

          </Form>
        </div>
      </Col>
    </Row>
  </>;
};

export default ForgotPassword;