import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { NumberFormatControl, SubmitButtons } from './Shared';
import { InvalidFeedback } from '../Shared/Helpers';
import { SwappingRule } from '../Shared/Layout';
import { initializeDefaults } from '../../helpers';
import config from '../../config';

const Blurb = ({ showSocialSecurityLink = true }) => {
  return <p>
    Now, we need to know about any income that you might receive in retirement.
    In each future scenario we evaluate (we'll run about 25,000 of these in just a few seconds), income will always be used first against your expenses before drawing from savings.
    {showSocialSecurityLink && <>And by the way, if you expect to receive Social Security and are not sure how much your benefit is, check out our <a href={config.SS_URL} target="_blank" rel="noopener noreferrer">Social Security Calculator</a>.</>}
  </p>
}

const HeadingWithSwitch = ({ title, description, id, value, loading, onChange }) => {
  return <Row className="align-items-center">
    <Col xs={true}>
      <h5 className="text-uppercase">{title}</h5>
      <p>{description}</p>
    </Col>
    <Col xs="auto">
      <div className="custom-control custom-switch-lg ml-3">
        <input type="checkbox" className="custom-control-input" id={id} checked={value} onChange={onChange} disabled={loading} />
        <label className="custom-control-label" htmlFor={id}></label>
      </div>
    </Col>
  </Row>;
}

const IncomeInputs = ({ id, owner = null, amountLabel = 'Amount at Commencement', commencementAges, commencementLabel = 'Commencement Age', hasInflation = false, value, errors, loading, onChange }) => {
  const defaults = { amount: '', commencementAge: '' };
  if (hasInflation) defaults.inflation = false;
  value = initializeDefaults(value, defaults);
  errors = initializeDefaults(errors, {});

  var ownerText = '';
  if (owner === 'primary') ownerText = 'Your';
  else if (owner === 'secondary') ownerText = 'Your Spouse\'s'

  function setValue(name, v) {
    value[name] = v;
    onChange({ ...value });
  }

  return <>
    <Form.Row>
      <Form.Group as={Col} sm={4} controlId={`form${id}Amount`}>
        <Form.Label>{ownerText} {amountLabel}</Form.Label>
        <NumberFormatControl value={value.amount} numberStyle="currency" append="/ month" onChange={e => setValue('amount', e.target.value)} isInvalid={!!errors.amount} disabled={loading} />
        <InvalidFeedback error={errors.amount} />
      </Form.Group>

      <Form.Group as={Col} sm={4} controlId={`form${id}CommencementAge`}>
        <Form.Label>{ownerText} {commencementLabel}</Form.Label>
        <Form.Control as="select" className="custom-select" value={value.commencementAge} onChange={e => setValue('commencementAge', e.target.value)} isInvalid={!!errors.commencementAge} disabled={loading}>
          <option></option>
          {commencementAges.map((y, i) => <option key={i} value={y}>{y}</option>)}
        </Form.Control>
        <InvalidFeedback error={errors.commencementAge} />
      </Form.Group>

      {hasInflation &&
        <Form.Group as={Col} sm={4} xs="auto" controlId={`form${id}Inflation`}>
          <Form.Label></Form.Label>
          <Form.Check type="switch" id={`form${id}InflationSwitch`} label="Inflation adjusted" className="mt-3" checked={value.inflation} onChange={e => setValue('inflation', e.target.checked)} disabled={loading} />
        </Form.Group>
      }
    </Form.Row>
  </>;
}

// const SocialSecurityInputs = ({ id, owner = null, claimingAges, value, errors, onChange, onFineTuneClick }) => {
//   value = initializeDefaults(value, { amount: '', commencementAge: '' });
//   errors = initializeDefaults(errors, {});

//   var ownerText = '';
//   if (owner === 'primary') ownerText = 'Your';
//   else if (owner === 'secondary') ownerText = 'Your Spouse\'s'

//   function setValue(name, v) {
//     value[name] = v;
//     onChange({ ...value });
//   }

//   return <>
//     <Form.Row>
//       <Form.Group as={Col} sm={4} controlId={`form${id}CurrentSalary`}>
//         <Form.Label>{ownerText} Current Salary</Form.Label>
//         <NumberFormatControl value={value.currentSalary} numberStyle="currency" append="/ month" onChange={e => setValue('currentSalary', e.target.value)} isInvalid={!!errors.currentSalary} />
//         <InvalidFeedback error={errors.amount} />
//         {onFineTuneClick && <Form.Text><Button variant="link">Fine tune my estimate</Button></Form.Text>}
//       </Form.Group>

//       <Form.Group as={Col} sm={4} controlId={`form${id}ClaimingAge`}>
//         <Form.Label>{ownerText} Claiming Age</Form.Label>
//         <Form.Control as="select" className="custom-select" value={value.claimingAge} onChange={e => setValue('claimingAge', e.target.value)} isInvalid={!!errors.claimingAge}>
//           <option></option>
//           {claimingAges.map((y, i) => <option key={i} value={y}>{y}</option>)}
//         </Form.Control>
//         <InvalidFeedback error={errors.claimingAges} />
//       </Form.Group>

//       <Form.Group as={Col} sm={4} xs="auto" controlId={`form${id}Estimate`}>
//         <Form.Label>{ownerText} Estimate</Form.Label>
//         <Form.Control plaintext readOnly defaultValue="$ -" />
//       </Form.Group>
//     </Form.Row>
//   </>;
// }

export default function Income({ value, errors, loading, primaryRetirementAge = 40, secondaryRetirementAge = 40, hasSecondary = false, showSocialSecurityLink = true, submitLabel, cancelLabel, showCancel, buttonAlignment, onChange, onSubmit, onCancel }) {

  value = initializeDefaults(value, {
    hasSocialSecurity: false,
    socialSecurity: {},
    hasPension: false,
    pension: {},
    hasAnnuity: false,
    annuity: {},
    hasOtherIncome: false,
    otherIncome: {}
  });

  errors = initializeDefaults(errors, {
    socialSecurity: {},
    pension: {},
    annuity: {},
    otherIncome: {}
  });

  const primaryClaimingAges = [...Array(70 - 62 + 1).keys()].map(y => 62 + y);
  const secondaryClaimingAges = [...Array(70 - 62 + 1).keys()].map(y => 62 + y);
  const primaryCommencementAges = [...Array(100 - (primaryRetirementAge || 40) + 1).keys()].map(y => (primaryRetirementAge || 40) + y);
  const secondaryCommencementAges = [...Array(100 - (secondaryRetirementAge || 40) + 1).keys()].map(y => (secondaryRetirementAge || 40) + y);

  function setValue(name, v) {
    value[name] = v;
    onChange({ ...value });
  }

  return <>
    <Row>
      <Col lg={8} className="p-3">
        <Form noValidate autoComplete="off" onSubmit={(e) => { e.preventDefault(); onSubmit(); return false; }}>
          <div className="d-lg-none mb-3">
            <Blurb showSocialSecurityLink={showSocialSecurityLink} />
            <hr />
          </div>

          <HeadingWithSwitch title="Social Security" id="socialSecuritySwitch"
            description={`Are you ${hasSecondary ? 'or your spouse' : ''} eligible to receive Social Security?`}
            value={value.hasSocialSecurity} loading={loading}
            onChange={e => setValue('hasSocialSecurity', e.target.checked)}
          />

          {value.hasSocialSecurity &&
            <>
              <IncomeInputs id="PrimarySocialSecurity"
                owner={hasSecondary ? "primary" : null}
                amountLabel="Benefit Amount"
                commencementAges={primaryClaimingAges}
                commencementLabel="Claiming Age"
                value={value.socialSecurity.primary}
                errors={errors && errors.socialSecurity && errors.socialSecurity.primary}
                loading={loading}
                onChange={v => onChange({...value, socialSecurity: { ...value.socialSecurity, primary: v }})} />

              {hasSecondary &&
                <IncomeInputs id="SecondarySocialSecurity"
                  owner="secondary"
                  amountLabel="Benefit Amount"
                  commencementAges={secondaryClaimingAges}
                  commencementLabel="Claiming Age"
                  value={value.socialSecurity.secondary}
                  errors={errors && errors.socialSecurity && errors.socialSecurity.secondary}
                  loading={loading}
                  onChange={v => onChange({...value, socialSecurity: { ...value.socialSecurity, secondary: v }})} />
              }

              <Form.Row>
                <Col xs={12} className="mb-3">
                  <Form.Text>The average benefit is $1,460 a month.</Form.Text>
                </Col>
              </Form.Row>
            </>
          }

          <hr className="mt-0" />

          <HeadingWithSwitch title="Pension" id="pensionSwitch"
            description={`Are you ${hasSecondary ? 'or your spouse' : ''} expecting to receive a pension?`}
            value={value.hasPension} loading={loading}
            onChange={e => setValue('hasPension', e.target.checked)}
          />

          {value.hasPension && 
            <>
              <IncomeInputs id="PrimaryPension"
                owner={hasSecondary ? "primary" : null}
                commencementAges={primaryCommencementAges}
                value={value.pension.primary}
                errors={errors && errors.pension && errors.pension.primary}
                loading={loading}
                onChange={v => onChange({...value, pension: { ...value.pension, primary: v }})} />

              {hasSecondary &&
                <IncomeInputs id="SecondaryPension"
                  owner="secondary"
                  commencementAges={secondaryCommencementAges}
                  value={value.pension.secondary}
                  errors={errors && errors.pension && errors.pension.secondary}
                  loading={loading}
                  onChange={v => onChange({...value, pension: { ...value.pension, secondary: v }})} />
              }

              <Form.Row>
                <Col xs={12} className="mb-3">
                  <Form.Text>The average American pension is $3,000 a month.</Form.Text>
                </Col>
              </Form.Row>
            </>
          }

          <hr className="mt-0" />
          
          <HeadingWithSwitch title="Annuity" id="annuitySwitch"
            description={`Will you ${hasSecondary ? 'or your spouse' : ''} be receiving income from an annuity?`}
            value={value.hasAnnuity} loading={loading}
            onChange={e => setValue('hasAnnuity', e.target.checked)}
          />

          {value.hasAnnuity &&
            <>
              <IncomeInputs id="PrimaryAnnuity"
                owner={hasSecondary ? "primary" : null}
                commencementAges={primaryCommencementAges}
                commencementLabel="Withdrawal Age"
                hasInflation={true}
                value={value.annuity.primary}
                errors={errors && errors.annuity && errors.annuity.primary}
                loading={loading}
                onChange={v => onChange({...value, annuity: { ...value.annuity, primary: v }})} />

              {hasSecondary &&
                <IncomeInputs id="SecondaryAnnuity"
                  owner="secondary"
                  commencementAges={secondaryCommencementAges}
                  commencementLabel="Withdrawal Age"
                  hasInflation={true}
                  value={value.annuity.secondary}
                  errors={errors && errors.annuity && errors.annuity.secondary}
                  loading={loading}
                  onChange={v => onChange({...value, annuity: { ...value.annuity, secondary: v }})} />
              }
            </>
          }

          <hr className="mt-0" />
          
          <HeadingWithSwitch title="Other Income" id="otherIncomeSwitch"
            description={`Are you ${hasSecondary ? 'or your spouse' : ''} expecting to receive any other income in retirement?`}
            value={value.hasOtherIncome} loading={loading}
            onChange={e => setValue('hasOtherIncome', e.target.checked)}
          />

          {value.hasOtherIncome &&
            <>
              <IncomeInputs id="PrimaryOtherIncome"
                owner={hasSecondary ? "primary" : null}
                commencementAges={primaryCommencementAges}
                hasInflation={true}
                value={value.otherIncome.primary}
                errors={errors && errors.otherIncome && errors.otherIncome.primary}
                loading={loading}
                onChange={v => onChange({...value, otherIncome: { ...value.otherIncome, primary: v }})} />

              {hasSecondary &&
                <IncomeInputs id="SecondaryOtherIncome"
                  owner="secondary"
                  commencementAges={secondaryCommencementAges}
                  hasInflation={true}
                  value={value.otherIncome.secondary}
                  errors={errors && errors.otherIncome && errors.otherIncome.secondary}
                  loading={loading}
                  onChange={v => onChange({...value, otherIncome: { ...value.otherIncome, secondary: v }})} />
              }
            </>
          }

          <hr className="mt-0" />

          <SubmitButtons {...{loading, submitLabel, cancelLabel, showCancel, buttonAlignment, onSubmit, onCancel}} />
        </Form>

      </Col>

      <SwappingRule breakpoint="sm" />

      <Col lg={4} className="p-3 d-none d-lg-block">

        <div>
          <Blurb showSocialSecurityLink={showSocialSecurityLink} />
        </div>

      </Col>
    </Row>
  </>
}