const currentYear = new Date().getUTCFullYear();
const minimumAge = 18;
const maximumAge = 90;
const minimumRetirementAge = 30;
const maximumRetirementAge = 100;

export default {
  colors: ['#00425e', '#30607c', '#54809b', '#77a1bb', '#9bc4dd', '#c0e8ff'],

  currentYear,
  
  minimumAge,
  maximumAge,
  minimumYearOfBirth: currentYear - minimumAge,
  maximumYearOfBirth: currentYear - maximumAge,

  minimumRetirementAge,
  maximumRetirementAge,
  minimumRetirementAgeYear: currentYear - minimumRetirementAge,
  maximumRetirementAgeYear: currentYear - maximumRetirementAge,

  genders: {
    Male: 'Male',
    Female: 'Female'
  },

  healthLevels: {
    Poor: 'Poor',
    Average: 'Average',
    AboveAverage: 'Above Average',
    Excellent: 'Excellent'
  },

  states: {
    "AL": "Alabama", "AK": "Alaska", "AZ": "Arizona", "AR": "Arkansas", "CA": "California",
    "CO": "Colorado", "CT": "Connecticut", "DE": "Delaware", "DC": "District of Columbia", "FL": "Florida",
    "GA": "Georgia", "HI": "Hawaii", "ID": "Idaho", "IL": "Illinois", "IN": "Indiana",
    "IA": "Iowa", "KS": "Kansas", "KY": "Kentucky", "LA": "Louisiana", "ME": "Maine",
    "MD": "Maryland", "MA": "Massachusetts", "MI": "Michigan", "MN": "Minnesota", "MS": "Mississippi",
    "MO": "Missouri", "MT": "Montana", "NE": "Nebraska", "NV": "Nevada", "NH": "New Hampshire",
    "NJ": "New Jersey", "NM": "New Mexico", "NY": "New York", "NC": "North Carolina", "ND": "North Dakota",
    "OH": "Ohio", "OK": "Oklahoma", "OR": "Oregon", "PA": "Pennsylvania", "RI": "Rhode Island",
    "SC": "South Carolina", "SD": "South Dakota", "TN": "Tennessee", "TX": "Texas", "UT": "Utah",
    "VT": "Vermont", "VA": "Virginia", "WA": "Washington", "WV": "West Virginia", "WI": "Wisconsin",
    "WY": "Wyoming"
  },

  allocations: [
    { id: 'bonds', label: 'U.S. Bonds and Cash' },
    { id: "largeCap", label: 'U.S. Large Cap' },
    { id: 'midCap', label: 'U.S. Small/Mid Cap' },
    { id: 'intl', label: 'Developed International Equities' },
    { id: 'emerging', label: 'Emerging Market Equities' }
  ],

  preallocations: [
    {
      label: 'Very Conservative',
      allocations: { bonds: 100 }
    },
    {
      label: 'Conservative',
      allocations: { bonds: 80, largeCap: 13, intl: 5, midCap: 2 }
    },
    {
      label: 'Moderately Conservative',
      allocations: { bonds: 61, largeCap: 24, intl: 10, midCap: 5 }
    },
    {
      label: 'Moderate',
      allocations: { bonds: 47, largeCap: 29, intl: 14, midCap: 10 }
    },
    {
      label: 'Moderately Aggressive',
      allocations: { bonds: 35, largeCap: 35, intl: 18, midCap: 12 }
    },
    {
      label: 'Aggressive',
      allocations: { bonds: 22, largeCap: 40, intl: 22, midCap: 16 }
    },
    {
      label: 'Very Aggressive',
      allocations: { largeCap: 55, intl: 25, midCap: 20 }
    }
  ],

  scoreRanges: [
    { min: 0,   max: 350, label: 'Very Poor', progress: 'below average', color: '#A5203F', color2: '#BF353E' },
    { min: 351, max: 550, label: 'Poor',      progress: 'below average', color: '#CF453C', color2: '#E85D3A' },
    { min: 551, max: 650, label: 'Fair',      progress: 'below average', color: '#FF6400', color2: '#F5802D' },
    { min: 651, max: 700, label: 'Good',      progress: 'average',       color: '#F78D26', color2: '#F9A11C' },
    { min: 701, max: 750, label: 'Very Good', progress: 'good',          color: '#FBAB17', color2: '#FCB710' },
    { min: 751, max: 850, label: 'Excellent', progress: 'great',         color: '#FDBF0C', color2: '#FFCB05' }
  ],

  reportScenarioTypes: {
    'GreatRecession': 'Great Recession',
    'MarketBoom': 'Market Boom',
    'MarketPlateau': 'Market Plateau',
    'ShortLifeExpectancy': 'Short Life Expectancy',
    'LongLifeExpectancy': 'Long Life Expectancy',
    'HyperInflation': 'Hyper-inflation'
  },

  reportScenarioHeadings: {
    'GreatRecession': 'The Great Recession',
    'MarketBoom': 'Market Boom',
    'MarketPlateau': 'Market Plateau',
    'ShortLifeExpectancy': 'Short Life Expectancy',
    'LongLifeExpectancy': 'Long Life Expectancy',
    'HyperInflation': 'Hyper-inflation'
  },

  reportScenarioDescriptions: {
    'GreatRecession': 'This scenario aims to simulate what would happen to your retirement portfolio if events similar to the great recession were to take place just before you retire.',
    'MarketBoom': 'This scenario aims to simulate what would happen to your retirement portfolio if a sustained period of market prosperity were to take place just as you retire.',
    'MarketPlateau': 'This scenario aims to simulate what would happen to your retirement portfolio if a sustained period of market inactivity were to take place just as you retire.',
    'ShortLifeExpectancy': 'This scenario aims to simulate what would happen to your retirement portfolio if your life expectancy was shortened to the lower 25th percentile of longevity scenarios.',
    'LongLifeExpectancy': 'This scenario aims to simulate what would happen to your retirement portfolio if your life expectancy was lengthened to the upper 75th percentile of longevity scenarios.',
    'HyperInflation': 'This scenario aims to simulate what would happen to your retirement portfolio if a sustained period of hyper-inflation was to take place just as you retire.'
  },

  reportScenarioChartLabels: {
    accountValue: () => 'Account Value',
    primaryContributions: (primary, secondary) => `${secondary ? (primary.name ? `${primary.name}'s ` : 'Your '): ''}Contributions`,
    secondaryContributions: (_, secondary) => `${secondary && secondary.name ? `${secondary.name}'s ` : 'Your Spouse\'s '}Contributions`,
    
    jointBasicLivingExpenses: (primary, secondary) => `${secondary ? 'Combined ' : (primary.name ? `${primary.name}'s ` : '')}Basic Living Expenses`,
    primaryBasicLivingExpenses: (primary, secondary) => `${secondary ? (primary.name ? `${primary.name}'s ` : 'Your ') : ''}Basic Living Expenses`,
    secondaryBasicLivingExpenses: (_, secondary) => `${secondary && secondary.name ? `${secondary.name}'s ` : 'Your Spouse\'s ' || ''}Basic Living Expenses`,
    primaryMedicalExpenses: (primary, secondary) => `${secondary ? (primary.name ? `${primary.name}'s ` : 'Your '): ''}Medical Expenses`,
    secondaryMedicalExpenses: (_, secondary) => `${secondary && secondary.name ? `${secondary.name}'s ` : 'Your Spouse\'s '}Medical Expenses`,
    
    primarySocialSecurity: (primary, secondary) => `${secondary ? (primary.name ? `${primary.name}'s ` : 'Your '): ''}Social Security`,
    primaryPension: (primary, secondary) => `${secondary ? (primary.name ? `${primary.name}'s ` : 'Your '): ''}Pension`,
    primaryAnnuity: (primary, secondary) => `${secondary ? (primary.name ? `${primary.name}'s ` : 'Your '): ''}Annuity`,
    primaryOtherIncome: (primary, secondary) => `${secondary ? (primary.name ? `${primary.name}'s ` : 'Your '): ''}Other Income`,
    secondarySocialSecurity: (_, secondary) => `${secondary && secondary.name ? `${secondary.name}'s ` : 'Your Spouse\'s '}Social Security`,
    secondaryPension: (_, secondary) => `${secondary && secondary.name ? `${secondary.name}'s ` : 'Your Spouse\'s '}Pension`,
    secondaryAnnuity: (_, secondary) => `${secondary && secondary.name ? `${secondary.name}'s ` : 'Your Spouse\'s '}Annuity`,
    secondaryOtherIncome: (_, secondary) => `${secondary && secondary.name ? `${secondary.name}'s ` : 'Your Spouse\'s '}Other Income`,

    withdrawals: () => 'Withdrawals'
  },
  
  incomeColors: ['#00876c', '#35987e', '#53a890', '#6eb9a2', '#87cab5', '#a1dcc8', '#baeddc'],
  expenseColors: ['#de425b', '#e65f6f', '#ed7883', '#f49097', '#f9a7ac', '#fcbec2', '#ffd5d7']
}