import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import ScoreTiles from './ScoreTiles';
import api from '../../services/api';
import { formatValue } from '../../helpers';

const increments = [
  { amount: 50, count: 8 },
  { amount: 100, count: 16 },
  { amount: 250, count: 12 },
  { amount: 500, count: 10 },
  { amount: 1000, count: 10 }
];

const calculatedIncrements = (() => {
  const calculated = [0];
  for (var i = 0; i < increments.length; ++i) {
    const inc = increments[i];
    for (var j = 0; j < inc.count; ++j)
      calculated.push(calculated[calculated.length - 1] + inc.amount);
  }
  return calculated;
})();

const closestIncrementValue = (value) => {
  var v = parseInt(value) || 0;
  var lastDifference = v;

  for (var i = 0; i < calculatedIncrements.length; ++i) {
    var inc = calculatedIncrements[i];
    var diff = Math.abs(v - inc);
    if (diff > lastDifference)
      return i - 1;
    lastDifference = diff;
  }
  return calculatedIncrements.length - 1;
};

const ContributionsSlider = ({ name, isSecondary, value, disabled, onChange }) => {
  const label = name || (!isSecondary ? 'You' : 'Your Spouse');
  
  return <Row className="justify-content-center">
    <Col xs={12} className="text-center py-4">
      <Row>
        <Col>
          <label htmlFor="customRange" className="text-center">{label} contributing <strong>{formatValue(calculatedIncrements[value], 'currency')}</strong> to your savings per month</label>
          <input type="range" className="custom-range" id="customRange" min={0} max={calculatedIncrements.length - 1}
            disabled={disabled}
            value={value}
            onChange={e => onChange(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6} className="text-left">
          <strong>{formatValue(calculatedIncrements[0], 'currency')}</strong>
        </Col>
        <Col xs={6} className="text-right">
          <strong>{formatValue(calculatedIncrements[calculatedIncrements.length - 1], 'currency')}</strong>
        </Col>
      </Row>
    </Col>
  </Row>;
}

const Contributions = ({ profile }) => {
  const hasSecondary = profile.profiles && profile.profiles.secondary && profile.profiles.secondary.yearOfBirth && profile.profiles.secondary.retirementAge ? true : false;
  const [primaryContributions, setPrimaryContributions] = useState(closestIncrementValue(profile.savings.primaryContributions));
  const [secondaryContributions, setSecondaryContributions] = useState(hasSecondary ? closestIncrementValue(profile.savings.secondaryContributions) : null);
  const timeout = useRef(null);
  const cancelTokenSource = useRef(null);
  const [loading, setLoading] = useState(true);
  const [locked, setLocked] = useState(true);
  const [scores, setScores] = useState(null);

  useEffect(() => {
    setLoading(true);

    if (timeout.current) clearTimeout(timeout.current);
    if (cancelTokenSource.current) cancelTokenSource.current.cancel('Cancelled');
    
    timeout.current = setTimeout(() => {
      setLocked(true);
      let primaryContributionsAmount = (primaryContributions !== null && parseInt(primaryContributions) >= 0)
        ? calculatedIncrements[parseInt(primaryContributions)]
        : null;
      let secondaryContributionsAmount = (secondaryContributions !== null && parseInt(secondaryContributions) >= 0)
        ? calculatedIncrements[parseInt(secondaryContributions)]
        : null;

      cancelTokenSource.current = api.getCancelTokenSource();
      api.simulatorContributions(primaryContributionsAmount, secondaryContributionsAmount, cancelTokenSource.current.token)
        .then(r => {
          setScores(r.data);
          setLoading(false);
          setLocked(false);
        })
        .catch(e => {
          if (!e.cancelled) {
            setLoading(false);
            setLocked(false);
          }
        });
    }, 1000);

    return function cleanup() {
      if (timeout.current) clearTimeout(timeout.current);
      if (cancelTokenSource.current) cancelTokenSource.current.cancel('Cancelled');
    }
  }, [primaryContributions, secondaryContributions]);

  return <Row>
    <ScoreTiles scores={scores} loading={loading} />

    <Col xs={12} className="p-3">
      <h5 className="text-uppercase mb-0">Contributions to Savings</h5>

      <ContributionsSlider name={profile.profiles.primary.name} isSecondary={false} value={primaryContributions}
        onChange={v => setPrimaryContributions(v)} disabled={locked} />

      {hasSecondary && <ContributionsSlider name={profile.profiles.secondary.name} isSecondary={true} value={secondaryContributions}
        onChange={v => setSecondaryContributions(v)} disabled={locked} />}

      <Row>
        <Col>
          <p>
            What is your savings rate? 5%? 10%? 50%?
            Financial stability in retirement has a lot to do with the savings you have accumulated over time.
            By contributing more to your savings now you can significantly grow your portfolio, harnessing the power of compounding market returns.
          </p>
        </Col>
      </Row>
    </Col>
  </Row>;
}

export default connect(state => ({
  profile: state.profile
}))(Contributions);