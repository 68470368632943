import React from 'react';
import { Link } from '@reach/router';
import { Row, Col, Nav } from 'react-bootstrap';

export default function Simulator({ children, ...props }) {
  const tab = props['*'];

  return <>
    <Row>
      <Col>

        <Row>
          <Col className="p-3">
            <h5 className="text-uppercase">RISE Score Simulator</h5>
            <p className="mb-0">Use the RISE Score Simulator to see how actions you could take today may impact your RISE Score, and ultimately your retirement security.</p>
          </Col>
        </Row>

        <Row>
          <Col sm={4} className="p-3">
            <Nav variant="pills" activeKey={tab} className="flex-column nav-underline">
              <Nav.Item>
                <Nav.Link as={Link} to="/simulator/age" eventKey="age" state={{dontScroll: true}}>Retirement Age</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/simulator/contributions" eventKey="contributions" state={{dontScroll: true}}>Contributions to Savings</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/simulator/allocations" eventKey="allocations" state={{dontScroll: true}}>Savings Allocation</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/simulator/income" eventKey="income" state={{dontScroll: true}}>Additional Income in Retirement</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/simulator/expenses" eventKey="expenses" state={{dontScroll: true}}>Expenses in Retirement</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link as={Link} to="/simulator/socialsecurity" eventKey="socialsecurity" state={{dontScroll: true}}>Social Security Claiming Age</Nav.Link>
              </Nav.Item> */}
              <Nav.Item>
                <Nav.Link as={Link} to="/simulator/annuities" eventKey="annuities" state={{dontScroll: true}}>Allocate to an Annuity</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          
          <Col sm={8}>
            {children}
          </Col>
        </Row>

      </Col>
    </Row>
  </>;
}
