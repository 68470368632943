import React, { useEffect, useState, useRef } from 'react';
import { ResponsiveContainer, Tooltip, XAxis, BarChart, Bar, YAxis, CartesianGrid, Rectangle } from 'recharts';
import { formatValue } from '../../helpers';

const barColors = {
  green: '#00A8AA',
  white: '#FFF',
  orange: '#FF6400',
  yellow: '#FFC800'
};

const barStyles = {
  excluded: {
    past: { stroke: barColors.white, fill: barColors.white },
    current: { stroke: barColors.white, fill: barColors.white },
    future: { stroke: barColors.white, fill: barColors.white }
  },
  included: {
    past: { stroke: barColors.yellow, fill: barColors.yellow },
    current: { stroke: barColors.orange, fill: barColors.orange },
    future: { stroke: barColors.green, fill: barColors.green }
  }
};

const formatBarData = (currentAge, earnings) => {
  const firstIndex = 0;
  const lastIndex = earnings.length - 1;

  return earnings.map((salary, index) => {
    const strokeType = salary.top35 === false ? 'excluded' : 'included';
    const offset = salary.age - currentAge;
    const fillType = offset > 0 ? 'future' : (offset < 0 ? 'past' : 'current');
    
    return {
      index,
      axis: fillType === 'current' ? 'current' : (index === firstIndex ? 'past' : (index === lastIndex ? 'future' : 'a')),
      age: salary.age,
      amount: salary.amount,
      ...barStyles[strokeType][fillType]
    };
  });
};

const CustomBar = ({ amount, fill, stroke, ...props }) => {
  return <Rectangle {...props} fill={fill} stroke={stroke} />
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className="recharts-default-tooltip" style={{
        margin: 0,
        padding: 10,
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        whiteSpace: 'nowrap'
      }}>
        <strong>Age {payload[0].payload.age}:</strong> {formatValue(payload[0].payload.amount, 'currency')}
      </div>
    );
  }

  return null;
};

const renderTick = ({ x, y, payload }, isSmall) => {
  const { value } = payload;
  if (value === 'past')
    return <text x={x} y={y + 12} textAnchor="start" fontWeight="bold">{`Historical${!isSmall ? ' Earnings' : ''}`}</text>;
  else if (value === 'current')
    return <text x={x} y={y + 12} textAnchor="middle" fontWeight="bold">{`Current${!isSmall ? ' Earnings' : ''}`}</text>;
  else if (value === 'future')
    return <text x={x} y={y + 12} textAnchor="end" fontWeight="bold">{`Future${!isSmall ? ' Earnings' : ''}`}</text>;
  return null;
};

export default function IncomeChart({ currentAge, data, onClick }) {
  const mapped = formatBarData(currentAge, data);
  const containerRef = useRef(null);
  const [width, setWidth] = useState(500);

  useEffect(() => {
    const handleResize = () => setWidth(containerRef.current.clientWidth);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function isMedium() {
    return width < 920;
  }

  return <div ref={containerRef}>
    <ResponsiveContainer height={isMedium() ? 300 : 500}>
      <BarChart data={mapped} margin={{ top: 24, right: 16, left: 16, bottom: 16 }} onClick={(e) => e && onClick && onClick(e.activeTooltipIndex)} style={{backgroundColor: '#f5f5f5'}}>
        <CartesianGrid vertical={false} stroke="#dddddd" />
        <XAxis dataKey="axis" tickLine={false} tick={p => renderTick(p, isMedium())} height={20} interval={0} />
        <YAxis scale="linear" tick={{fontSize: 11, fontFamily: 'sans-serif'}} axisLine={false} width={40} tickFormatter={t => `${formatValue(t / 1000, 'currency')}k` } />
        <Tooltip content={CustomTooltip} />
        <Bar dataKey="amount" shape={CustomBar} />
      </BarChart>
    </ResponsiveContainer>
  </div>;
}