import React, { useState, useEffect, useRef } from 'react';
import { Router, Redirect, Location } from '@reach/router'
import { Container } from 'react-bootstrap';

import { Provider, connect } from 'react-redux';
import store from './store';
import { ADD_PROFILES, SET_INITIALIZED, SET_ISLOGGEDIN } from './store/actionTypes';

import { hotjar } from 'react-hotjar';

import './styles/rise.scss';

import AppBar from './components/Shared/AppBar';
import Disclaimer from './components/Shared/Disclaimer';
import Footer from './components/Shared/Footer';
import LoadingModal from './components/Shared/LoadingModal';

import Score from './components/Score';
import ScoreSummary from './components/ScoreSummary';
import SocialSecurity from './components/SocialSecurity';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import NotFound from './components/NotFound';

import Overview from './components/Overview';
import { Profile, ProfileWrapper } from './components/Profile';
import Profiles from './components/Profile/Profiles';
import SavingsContributions from './components/Profile/SavingsContributions';
import Allocations from './components/Profile/Allocations';
import Income from './components/Profile/Income';
import Expenses from './components/Profile/Expenses';
import Account from './components/Profile/Account';

import Report from './components/Report';
import Simulator from './components/Simulator';
import SimulatorRetirementAge from './components/Simulator/RetirementAge';
import SimulatorContributions from './components/Simulator/Contributions';
import SimulatorAllocations from './components/Simulator/Allocations';
import SimulatorIncome from './components/Simulator/Income';
import SimulatorExpenses from './components/Simulator/Expenses';
import SimulatorAnnuities from './components/Simulator/Annuities';

import api from './services/api';
import config from './config';

hotjar.initialize(config.ANALYTICS_HOTJAR, 6);

const AppContainer = ({ children }) => {
  return (
    <Container id="app">
      {children}
    </Container>
  );
}

const ProtectedArea = connect(state => ({ isLoggedIn: state.isLoggedIn }))(({ isLoggedIn, children, navigate }) => {
  useEffect(() => {
    if (!isLoggedIn)
      window.location.href = '/login';
  }, [isLoggedIn]);

  if (!isLoggedIn)
    return null;

  return <>{children}</>;
});

const AutoScroller = () => {
  const Scroller = ({ location }) => {
    const lastLocation = useRef(null);
    if (lastLocation.current && location.pathname !== lastLocation.current.pathname && (!location.state || !location.state.dontScroll))
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    lastLocation.current = location;
    return null;
  };

  return <Location>
    {({ location }) => <Scroller location={location} />}
  </Location>;
}

const Analytics = () => {
  const AnalyticsWorker = ({ location }) => {
    if (window.gtag) window.gtag('event', 'page_view', { 'page_path': location.pathname + location.search });
    return null;
  };

  return <Location>
    {({ location }) => <AnalyticsWorker location={location} />}
  </Location>;
}

const Initializer = connect(state => ({ isInitialized: state.isInitialized }))(({ isInitialized, dispatch, children }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isInitialized) {
      setLoading(true);
      api.getProfile(true)
        .then(r => {
          dispatch({ type: ADD_PROFILES, profiles: r.data });
          dispatch({ type: SET_ISLOGGEDIN, isLoggedIn: true });
          dispatch({ type: SET_INITIALIZED, isInitialized: true });
          setLoading(false);
        })
        .catch(e => {
          dispatch({ type: SET_ISLOGGEDIN, isLoggedIn: false });
          dispatch({ type: SET_INITIALIZED, isInitialized: true });
          setLoading(false);
        });
    }
  }, [isInitialized, dispatch, setLoading]);

  if (!isInitialized)
    return null;

  return <>{children}</>;
});

const App = () => {
  return (
    <Provider store={store}>
      <Initializer>
        <AppBar />

        <Disclaimer />

        <AppContainer>
          <Router primary={false}>
            <Score path="/score" />
            <ScoreSummary path="/score/:scoreId" />

            <SocialSecurity path="/social-security-benefit" />

            <Login path="/login" />
            <ForgotPassword path="/forgot-password" />
            <ResetPassword path="/reset-password" />
            <Redirect from="/" to="/overview" noThrow />
            <NotFound default />

            <ProtectedArea path="/">
              <Overview path="/overview" />
              
              <Redirect from="/profile" to="/profile/about" noThrow />
              <Profile path="/profile">
                <ProfileWrapper path="/about" child={Profiles} />
                <ProfileWrapper path="/savings" child={SavingsContributions} />
                <ProfileWrapper path="/allocations" child={Allocations} />
                <ProfileWrapper path="/income" child={Income} />
                <ProfileWrapper path="/expenses" child={Expenses} />
                <Account path="/account" />
              </Profile>
              
              <Report path="/report" />
              <Report path="/report/:reportId" />
              
              <Redirect from="/simulator" to="/simulator/age" noThrow />
              <Simulator path="/simulator">
                <SimulatorRetirementAge path="/age" />
                <SimulatorContributions path="/contributions" />
                <SimulatorAllocations path="/allocations" />
                <SimulatorIncome path="/income" />
                <SimulatorExpenses path="/expenses" />
                <SimulatorAnnuities path="/annuities" />
              </Simulator>
              
              <NotFound default />
            </ProtectedArea>
          </Router>

          <AutoScroller />
          <Analytics />
        </AppContainer>

        <Footer />
      </Initializer>
    </Provider>
  );
}

export default App;
