import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ADD_PROFILES, ADD_REPORT_LATEST } from '../store/actionTypes';
import api from '../services/api';

import { Link } from '@reach/router';
import { Row, Col } from 'react-bootstrap';
import { Tip } from './Report/Tips'
import { SwappingRule } from '../components/Shared/Layout';
import { formatValue, findPreSelectedAllocation } from '../helpers';
import constants from '../constants';
import moment from 'moment';

import BehaviorIcon from '../images/behavior.svg';

const ScoreTile = ({ report }) => {
  const hasReport = report ? true : false;
  const isReady = hasReport && report.ready ? true : false;
  const score = isReady ? report.score : null;

  var roundedScore = null;
  if (score != null) {
    if (score < 550) roundedScore = 350;
    else if (score < 650) roundedScore = 550;
    else if (score < 700) roundedScore = 650;
    else if (score < 750) roundedScore = 700;
    else if (score < 800) roundedScore = 750;
    else if (score <= 850) roundedScore = 850;
  }

  return <div className="w-100">

    <h5 className="text-uppercase mb-1">Your Score</h5>

    <Row className="justify-content-center align-items-center">
      <Col xs={11} md={10} lg={9} xl={7}>

        <div className={`score-sun ${roundedScore ? `score-${roundedScore}` : ''}`}>
          <div className="sun">
            <div className="label">
              <Row className="align-items-center justify-content-center">
                <Col xs="auto" className="px-2">
                  <h1 className="display-1">{isReady ? score : '---'}</h1>
                </Col>
                <Col xs="auto" className="px-2 my-2">
                  <h6 className="text-uppercase mb-0">RISE Score</h6>
                  <span className="small">
                    {isReady && `Calculated ${report ? moment(report.created).format('MMM D, YYYY') : '---'}`}
                    {!isReady && 'Update in progress'}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="my-2">
                  <Link className="btn btn-primary" to={isReady ? `/report/${report.id}` : ''}>View Report</Link>
                </Col>
              </Row>
            </div>
          </div>
        </div>
            
      </Col>
    </Row>

  </div>
};

const ProfileTile = ({ profile }) => {
  const hasSecondary = profile && profile.profiles && profile.profiles.hasSecondary;

  const allocationIndex = findPreSelectedAllocation(profile && profile.allocations);

  function accountCount(account) {
    if (account) {
      return (account.primary && account.primary.amount && account.primary.commencementAge && 1)
        + (account.secondary && account.secondary.amount && account.secondary.commencementAge && 1);
    }
    return 0;
  }

  const incomeAccounts = (profile && profile.income && (
    (profile.income.hasSocialSecurity ? accountCount(profile.income.socialSecurity) : 0) +
    (profile.income.hasPension ? accountCount(profile.income.pension) : 0) +
    (profile.income.hasAnnuity ? accountCount(profile.income.annuity) : 0) +
    (profile.income.hasOtherIncome ? accountCount(profile.income.otherIncome) : 0)
  )) || 0;

  const expenses = (profile && profile.expenses && (
    (profile.expenses.jointBasicLivingExpenses || 0) + 
    (profile.expenses.primaryBasicLivingExpenses || 0) + 
    (profile.expenses.secondaryBasicLivingExpenses || 0) + 
    (profile.expenses.primaryMedicalExpenses || 0) + 
    (profile.expenses.secondaryMedicalExpenses || 0)
  )) || 0;

  return <>
    <h5 className="text-uppercase mb-3">Your Profile</h5>
    <p>Keeping your profile up-to-date will help keep your retirement goals on track.</p>

    <p>
      <Link to="/profile/about">
        {!hasSecondary ? 'Yourself' : `You & ${profile.profiles.secondary.name || 'Your Spouse'}`}
      </Link><br />

      <Link to="/profile/savings">
        {formatValue((profile && profile.savings && profile.savings.savings) || '0', 'currency')} in Total Savings
      </Link><br />

      <Link to="/profile/allocations">
        {allocationIndex ? constants.preallocations[allocationIndex].label : 'Custom'} Asset Allocation
      </Link><br />

      <Link to="/profile/income">
        {incomeAccounts === 1 ? `${incomeAccounts} Source` : `${incomeAccounts} Sources`} of Retirement Income
      </Link><br />

      <Link to="/profile/expenses">
        {formatValue(expenses, 'currency')} of Expenses in Retirement
      </Link>
    </p>

    <span className="small">Last updated {moment(profile.updated).format('MMM D, YYYY')}</span>

    {/* <hr />

    <div style={{display: 'flex'}}>
      <div style={{flex: 1}}>Link your external accounts to automate account updates</div>
      <div style={{flex: 0}}><Button>Link</Button></div>
    </div> */}
  </>;
}

const Overview = ({ latestReport, topTips, profile, dispatch }) => {
  const [loading, setLoading] = useState(true);

  const hasReport = latestReport ? true : false;
  const isReady = hasReport && latestReport.ready ? true : false;
  const hasSecondary = isReady && latestReport.secondaryYearOfBirth && latestReport.secondaryRetirementAge ? true : false;
  
  useEffect(() => {
    var intervalId = null;

    function refresh() {
      api.getReportLatest()
        .then(r => {
          if (r.data && r.data.ready) {
            dispatch({ type: ADD_REPORT_LATEST, report: r.data });
            clearInterval(intervalId);
          }
        });
    }

    setLoading(true);
    Promise.all([
        api.getProfile(),
        api.getReportLatest()
      ])
      .then(rs => {
        dispatch({ type: ADD_PROFILES, profiles: rs[0].data });
        dispatch({ type: ADD_REPORT_LATEST, report: rs[1].data });
        setLoading(false);

        if (rs[1].data && !rs[1].data.ready) {
          intervalId = setInterval(refresh, 2500);
        }
      })
      .catch(e => {
        setLoading(false);
      });

    return function cleanup() {
      clearInterval(intervalId);
    }
  }, [dispatch, setLoading]);

  return <>
    <Row style={{opacity: loading ? 0 : 1, transition: '1.5s opacity'}}>
      <Col>

        <Row>
          <Col sm={6} className="p-3">
            <ScoreTile report={latestReport} />
          </Col>

          <SwappingRule breakpoint="sm" />

          <Col sm={6} className="p-3">
            <ProfileTile profile={profile} />
          </Col>
        </Row>

        <div className="px-3">
          <hr className="my-0" />
        </div>
      
        <Row>
          <Col sm={12}>

            <h5 className="text-uppercase mb-0 mt-3 mx-3">Ways You May Improve Your Score</h5>

            <Row>
              {hasReport && !isReady &&
                <Col xs={12} className="p-5 text-center">
                  <img src={BehaviorIcon} style={{width: '25%', maxWidth: 100}} className="my-3" alt="" />
                  <p>Hang tight! We're still calculating your RISE report and tips.</p>
                </Col>
              }

              {isReady && topTips[0] &&
                <Tip tip={topTips[0]} tipCount={topTips.length} hasSecondary={hasSecondary} />
              }

              {isReady && topTips[1] && <>
                <SwappingRule breakpoint="sm" />
                <Tip tip={topTips[1]} tipCount={topTips.length} hasSecondary={hasSecondary} />
              </>}
              
              {isReady && topTips[2] && <>
                <SwappingRule breakpoint="md" />
                <Tip tip={topTips[2]} tipCount={topTips.length} hasSecondary={hasSecondary} />
              </>}
              
              {isReady && topTips[3] && <>
                <SwappingRule breakpoint="sm" />
                <Tip tip={topTips[3]} tipCount={topTips.length} hasSecondary={hasSecondary} />
              </>}
            </Row>

          </Col>
        </Row>

      </Col>
    </Row>
  </>;
}

export default connect(state => {
  const latestReport = state.reports.latest;
  const topTips = (latestReport && latestReport.tips) || [];
  topTips.sort((a, b) => a.improvement < b.improvement ? 1 : -1);
  topTips.splice(4, topTips.length - 4); // cap to top 4

  return { latestReport, topTips, profile: state.profile };
})(Overview);