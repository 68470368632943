import { ADD_REPORT_LATEST, ADD_REPORT, ADD_REPORTS, RESET } from '../actionTypes';

const initialState = {
  latest: null,
  byKey: {},
  summaries: []
};

export default function(state = initialState, action) {

  switch (action.type) {
    case ADD_REPORT_LATEST: {
      const newState = {...state};
      newState.latest = action.report;
      newState.byKey = {...newState.byKey};
      newState.byKey[action.report.id] = action.report;
      return newState;
    }
    case ADD_REPORT: {
      const newState = {...state};
      newState.byKey = {...newState.byKey};
      newState.byKey[action.report.id] = action.report;
      return newState;
    }
    case ADD_REPORTS: {
      return {
        ...state,
        summaries: action.reports
      };
    }
    case RESET: {
      return initialState;
    }
    default: {
      return state;
    }
  }

}