import React, { useState } from 'react';
import { Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { InvalidFeedback } from './Shared/Helpers';
import { Link } from '@reach/router';
import auth from '../services/auth';
import validators from '../services/validators';

const Login = ({ location }) => {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const newAccount = (location && location.state && location.state.newAccount) || false;
  const passwordReset = (location && location.state && location.state.passwordReset) || false;
  const accountClosed = (location && location.state && location.state.accountClosed) || false;

  function handleSubmit() {
    const e = validators.validateLogin(fields);
    setErrors(e);

    if (validators.isValid(e)) {
      setLoading(true);
      auth.login({...fields})
        .then(r => {
          window.location.href = '/overview';
        })
        .catch(e => {
          if (e.status === 400)
            setErrors({ '': 'Invalid email/password combination.' });
          else
            setErrors({ '': 'An unexpected error has occured.' });
          setLoading(false);
        });
    }
  }

  return <>
    <Row className="justify-content-center align-items-center" style={{minHeight: '50vh'}}>
      <Col lg={6} className="p-4 p-lg-5 justify-content-center d-flex align-items-center">
        <div className="w-100">

          {newAccount &&
            <Alert variant="success">
              <h6 className="text-uppercase">Thanks for registering</h6>
              You may log in to your newly created account below.
            </Alert>
          }

          {passwordReset &&
            <Alert variant="success">
              Your new password was successfully reset. Please try logging in below.
            </Alert>
          }

          {accountClosed &&
            <Alert variant="success">
              Your account has been closed.
            </Alert>
          }

          <h5 className="text-uppercase mb-3">Login</h5>

          <Form noValidate autoComplete="off" onSubmit={(e) => { e.preventDefault(); handleSubmit(); return false; }}>

            {!!errors[''] && <Alert variant="danger">{errors['']}</Alert> }

            <Form.Group as={Col} controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" value={fields.email || ''} onChange={e => setFields({...fields, email: e.target.value})} isInvalid={!!errors.email} disabled={loading} />
              <InvalidFeedback error={errors.email} />
            </Form.Group>

            <Form.Group as={Col} controlId="formPassword">
              <Link className="small float-right" to="/forgot-password">Forgot Password?</Link>
              <Form.Label>
                Password
              </Form.Label>
              <Form.Control type="password" value={fields.password || ''} onChange={e => setFields({...fields, password: e.target.value})} isInvalid={!!errors.password} disabled={loading} />
              <InvalidFeedback error={errors.password} />
            </Form.Group>

            <Form.Group as={Col} className="mb-4">
              <Button variant="primary" type="submit" disabled={loading} className="w-100">
                Login
              </Button>
            </Form.Group>
              
            <Form.Group as={Col} className="text-center">
              <h6>Don't have an account?</h6>
              <Link to="/score">Get started with a RISE Score and account.</Link>
            </Form.Group>

          </Form>
        </div>
      </Col>
    </Row>
  </>;
};

export default Login;