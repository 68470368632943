import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';

const ScoreTile = ({ score, created }) => {
    const [roundedScore, setRoundedScore] = useState(null);
  
    useEffect(() => {
      setRoundedScore(null);
      setTimeout(() => {
        if (score != null) {
          if (score < 550) setRoundedScore(350);
          else if (score < 650) setRoundedScore(550);
          else if (score < 700) setRoundedScore(650);
          else if (score < 750) setRoundedScore(700);
          else if (score < 800) setRoundedScore(750);
          else if (score <= 850) setRoundedScore(850);
        }
      }, 100);
    }, [score, setRoundedScore]);
  
    return <div className="w-100">
      <Row className="justify-content-center align-items-center">
        <Col xs={11} md={10} lg={9} xl={7}>
  
          <div className={`score-sun ${roundedScore ? `score-${roundedScore}` : ''}`}>
            <div className="sun">
              <div className="label">
                <Row className="align-items-center justify-content-center">
                  <Col xs="auto" className="px-2">
                    <h1 className="display-1">{score || '---'}</h1>
                  </Col>
                  <Col xs="auto" className="px-2">
                    <h6 className="text-uppercase mb-0">RISE Score</h6>
                    <span className="small">{created ? `Calculated ${moment.utc(created).format('MMM D, YYYY')}` : 'Update in progress'}</span>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
              
        </Col>
      </Row>
    </div>
  };

  export default ScoreTile;