import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';

if (process.env.REACT_APP_BUILD_ENV === 'whitelabel') {
    const WhiteLabel = require('./components/WhiteLabel/App').default;
    ReactDOM.render(<WhiteLabel />, document.querySelector("#root"));
}
else {
    const App = require('./App').default;
    ReactDOM.render(<App />, document.querySelector("#root"));
}