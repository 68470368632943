import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';

function roundScore(score) {
  if (score !== null) {
    if (score < 550) return 350;
    else if (score < 650) return 550;
    else if (score < 700) return 650;
    else if (score < 750) return 700;
    else if (score < 800) return 750;
    else if (score <= 850) return 850;
  }
  return null;
}

const ScoreTiles = ({ scores, loading }) => {
  const [roundedScores, setRoundedScores] = useState(null);

  useEffect(() => {
    setRoundedScores(null);
    setTimeout(() => {
      if (scores) {
        setRoundedScores({
          reportScore: roundScore(scores.reportScore),
          simulatedScore: roundScore(scores.simulatedScore)
        });
      }
    }, 50);
  }, [scores, setRoundedScores]);

  return <>
    <Col sm={6} className="p-3 d-flex align-items-center">
      <div className="w-100">
        <Row className="justify-content-center align-items-center mb-3">
          <Col xs={11} md={10} lg={9} xl={7}>
            <div className={`score-sun ${roundedScores ? `score-${roundedScores.reportScore}` : ''}`}>
              <div className="sun">
                <div className="label">
                  <Row className="align-items-center justify-content-center">
                    {scores && scores.reportScore !== null && <>
                      <Col xs={12} className="px-2">
                        <h1 className="display-1">{scores ? scores.reportScore : '---'}</h1>
                      </Col>
                      <Col xs={12} className="px-2">
                        <h6 className="text-uppercase mb-0">RISE Score</h6>
                      </Col>
                    </>}
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="text-center">
          <Col>
            <h6 className="">Your Current Score</h6>
            <span className="small">Based on your profile from {scores ? moment.utc(scores.reportCreated).format('MMM D, YYYY') : '---'}</span>
          </Col>
        </Row>
      </div>
    </Col>

    <Col sm={6} className="p-3 d-flex align-items-center">
      <div className="w-100">
        <Row className="justify-content-center align-items-center mb-3">
          <Col xs={11} md={10} lg={9} xl={7}>
            <div className={`score-sun ${roundedScores ? `score-${roundedScores.simulatedScore}` : ''}`}>
              <div className="sun">
                <div className="label">
                  <Row className="align-items-center justify-content-center">
                    {!loading && <>
                      <Col xs={12} className="px-2">
                        <h1 className="display-1">{scores && !loading ? scores.simulatedScore : '---'}</h1>
                      </Col>
                      <Col xs={12} className="px-2">
                        <h6 className="text-uppercase mb-0">RISE Score</h6>
                      </Col>
                    </>}
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="text-center">
          <Col>
            <h6 className="">Your Simulated Score</h6>
            <span className="small">Based on the below changes</span>
          </Col>
        </Row>
      </div>
    </Col>
  </>;
};

export default ScoreTiles;