import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import IncomeChart from './IncomeChart';
import IncomeModal from './IncomeModal';
import EarningsService from '../../services/socialsecurity';

export default function EarningsInputView({ value, loading, error, onBack, onNext }) {

  const [values, setValues] = useState(() => {
    if (value.earnings)
      return value.earnings;
    
    const initial = {};
    initial[value.currentAge] = value.currentEarnings;
    return initial;
  });
  const [earnings, setEarnings] = useState(null);
  const [editEarnings, setEditEarnings] = useState({ open: false });

  useEffect(() => {
    if (values && Object.keys(values).length > 0)
      setEarnings(
        EarningsService.project(value.currentAge, value.retirementAge, value.claimAge, values)
      );
  }, [value, values]);

  return <>
    <Row>
      <Col xs={12} className="p-4">

        <div>
          <h2>Your Earnings History</h2>
          <p>All past and future earnings are used to estimate your social security benefit. We've tried to estimate these for you using your current earnings and national wage inflation averages. If these don't look correct, you may change these by selecting individual bars. You don't need to provide every year as we'll do our best to fill in the gaps.</p>
        </div>

        <hr />

        <div>
          <h3>Your earnings history</h3>
          <Row>
            <Col xs={12}>
              <div>
                {earnings && earnings.length > 0 &&
                  <IncomeChart currentAge={value.currentAge} data={earnings}
                    onClick={index => {
                      if (!loading) {
                        const age = earnings[index].age;
                        setEditEarnings({
                          open: true,
                          age,
                          isSet: age !== value.currentAge && values && !isNaN(parseInt(values[age])),
                          amount: (values && !isNaN(parseInt(values[age])) ? values[age] : null) || Math.round(earnings[index].amount * 100) / 100
                        });
                      }
                    }}
                  />
                }
              </div>
            </Col>
          </Row>
        </div>

        <hr />

        {error &&
          <Alert variant="danger">
            <span className="text-danger mr-2" style={{verticalAlign: 'middle', lineHeight: '1rem', fontSize: '3rem'}}>&times;</span>
            <strong style={{verticalAlign: 'middle'}}>Oops, something went wrong. Please try again.</strong>
          </Alert>
        }

        <div className="py-3">
          <Row style={{justifyContent: 'space-between'}}>
            <Col xs={5} sm={4} md={3} lg={2}>
              <Button className="backButton" variant="outline-primary" onClick={onBack} style={{width: '100%'}} disabled={loading}>Go Back</Button>
            </Col>
            <Col xs={5} sm={4} md={3} lg={2}>
              <Button className="nextButton" onClick={() => onNext({ earnings: values })} style={{width: '100%'}} disabled={loading}>
                {loading 
                  ? <><i className="fas fa-sun fa-spin"></i> Calculating...</>
                  : <>Calculate</>
                }
              </Button>
            </Col>
          </Row>
        </div>

      </Col>
    </Row>

    <IncomeModal value={editEarnings}
      onAccept={(age, amount) => {
        const newValues = { ...values };
        newValues[age] = amount;
        setValues(newValues);
        setEditEarnings(e => { return { ...e, open: false, age, amount }; });
      }}
      onUnset={(age) => {
        const newValues = { ...values };
        delete newValues[age];
        setValues(newValues);
        setEditEarnings(e => { return { ...e, open: false, age }; });
      }}
      onClose={() => setEditEarnings(e => { return { ...e, open: false }; })}
    />
  </>;
}