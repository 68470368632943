import React, { useState, useEffect } from 'react';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { formatValue } from '../../helpers';

export function SubmitButtons({ loading, buttonAlignment, submitLabel, cancelLabel, showCancel = true, onSubmit, onCancel }) {
  return <div className={buttonAlignment === 'right' ? 'text-right' : null}>
      {buttonAlignment === 'right' && showCancel &&
        <>
          <Button variant="outline-primary" onClick={onCancel} disabled={loading}>
            {cancelLabel || 'Cancel'}
          </Button>&nbsp;
        </>
      }

      <Button variant="primary" type="submit" onClick={e => { onSubmit(); e.preventDefault(); e.stopPropagation(); return false; }} disabled={loading}>
        {submitLabel || 'Save'}
      </Button>

      {buttonAlignment !== 'right' && showCancel &&
        <>
          &nbsp;<Button variant="outline-primary" onClick={onCancel} disabled={loading}>
            {cancelLabel || 'Cancel'}
          </Button>
        </>
      }
    </div>;
}

export function NumberFormatControl({ value, append = null, numberStyle = 'decimal', onChange, ...props }) {
  const [focused, setFocused] = useState(false);
  const [internalValue, setInternalValue] = useState(value);
  const { isInvalid } = props;

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  function handleFocus() {
    setFocused(true);
  }

  function handleBlur() {
    setFocused(false);
    onChange && onChange({ target: { value: internalValue }});
  }

  function handleChange(e) {
    setInternalValue(e.target.value);
  }

  var fieldValue = focused || isNaN(parseFloat(internalValue)) || !isFinite(internalValue)
    ? internalValue
    : formatValue(internalValue, numberStyle);
  
  const control = <Form.Control {...props}
    onFocus={handleFocus}
    onBlur={handleBlur}
    value={fieldValue === null || fieldValue === undefined ? '' : fieldValue}
    onChange={handleChange}
  />;

  if (append && append.length > 0)
    return <InputGroup className={isInvalid ? 'is-invalid' : ''}>
        {control}
        <InputGroup.Append>
          <InputGroup.Text>{append}</InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>

  return control;
}

export function LoadingModal({ loading = false, message = 'Calculating your RISE Score' }) {
  return <Modal show={loading} centered onHide={() => {}}>
    <Modal.Body>
      <h5 className="text-uppercase text-center">{message}</h5>
      <div style={{height: 'calc(256px + 1rem)', width: 256, overflow: 'hidden', position: 'relative', margin: '0 auto', marginTop: '2rem', marginBottom: '-1rem'}}>
        <div className="big-sun"></div>
      </div>
    </Modal.Body>
  </Modal>;
}