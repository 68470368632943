import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import ScoreTiles from './ScoreTiles';
import api from '../../services/api';

const RetirementAgeSlider = ({ currentAge, name, isSecondary, value, disabled, onChange }) => {
  const label = name ? `${name}'s` : (!isSecondary ? 'Your' : 'Your Spouse\'s');

  return <Row className="justify-content-center">
    <Col xs={12} className="text-center py-4">
      <Row>
        <Col>
          <label htmlFor="customRange" className="text-center">{label} anticipated retirement age of <strong>{value}</strong></label>
          <input type="range" className="custom-range" id="customRange" min={currentAge} max="90"
            disabled={disabled}
            value={value}
            onChange={e => onChange(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6} className="text-left">
          <strong>{currentAge}</strong>
        </Col>
        <Col xs={6} className="text-right">
          <strong>90</strong>
        </Col>
      </Row>
    </Col>
  </Row>;
}

const RetirementAge = ({ profile }) => {
  const hasSecondary = profile.profiles && profile.profiles.secondary && profile.profiles.secondary.yearOfBirth && profile.profiles.secondary.retirementAge ? true : false;
  const [primaryRetirementAge, setPrimaryRetirementAge] = useState(profile.profiles.primary.retirementAge);
  const [secondaryRetirementAge, setSecondaryRetirementAge] = useState(hasSecondary ? profile.profiles.secondary.retirementAge : null);
  const timeout = useRef(null);
  const cancelTokenSource = useRef(null);
  const [loading, setLoading] = useState(true);
  const [locked, setLocked] = useState(true);
  const [scores, setScores] = useState(null);

  const primaryAge = Math.min(Math.max(new Date().getUTCFullYear() - profile.profiles.primary.yearOfBirth, 30), 90);
  const secondaryAge = hasSecondary ? Math.min(Math.max(new Date().getUTCFullYear() - profile.profiles.secondary.yearOfBirth, 30), 90) : null;

  useEffect(() => {
    setLoading(true);

    if (timeout.current) clearTimeout(timeout.current);
    if (cancelTokenSource.current) cancelTokenSource.current.cancel('Cancelled');
    
    timeout.current = setTimeout(() => {
      setLocked(true);

      cancelTokenSource.current = api.getCancelTokenSource();
      api.simulatorAge((primaryRetirementAge && parseInt(primaryRetirementAge)) || null, (secondaryRetirementAge && parseInt(secondaryRetirementAge)) || null, cancelTokenSource.current.token)
        .then(r => {
          setScores(r.data);
          setLoading(false);
          setLocked(false);
        })
        .catch(e => {
          if (!e.cancelled) {
            setLoading(false);
            setLocked(false);
          }
        });
    }, 1000);

    return function cleanup() {
      if (timeout.current) clearTimeout(timeout.current);
      if (cancelTokenSource.current) cancelTokenSource.current.cancel('Cancelled');
    }
  }, [primaryRetirementAge, secondaryRetirementAge]);

  return <Row>
    <ScoreTiles scores={scores} loading={loading} />

    <Col xs={12} className="p-3">
      <h5 className="text-uppercase mb-0">Retirement Age</h5>

      <RetirementAgeSlider currentAge={primaryAge} name={profile.profiles.primary.name} isSecondary={false} value={primaryRetirementAge}
        onChange={v => setPrimaryRetirementAge(v)} disabled={locked} />

      {hasSecondary && <RetirementAgeSlider currentAge={secondaryAge} name={profile.profiles.secondary.name} isSecondary={true} value={secondaryRetirementAge}
        onChange={v => setSecondaryRetirementAge(v)} disabled={locked} />}

      <Row>
        <Col>
          <p>
            Retiring early is an achievable goal, but this might mean that you need to rely more on your savings portfolio.
            Withdrawing from your portfolio early could impact future portfolio growth, minimizing benefits from compounding, and may also come with early withdrawal penalties if withdrawing from tax-advantaged retirement accounts.
            But, it sure would be nice to retire when you want <i>and</i> live the lifestyle you've always dreamed of.
          </p>
        </Col>
      </Row>
    </Col>
  </Row>;
};

export default connect(state => ({
  profile: state.profile
}))(RetirementAge);