import React, { useState, useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import { LoadingModal } from '../Profile/Shared';
import Profiles from '../Profile/Profiles';
import SavingsContributions from '../Profile/SavingsContributions';
import Allocations from '../Profile/Allocations';
import Income from '../Profile/Income';
import Expenses from '../Profile/Expenses';
import validators from '../../services/validators';
import api from '../../services/api';

export default function Score({ navigate, location }) {
  const [tab, setTab] = useState('profiles');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [fields, setFields] = useState({
    profiles: location.state ? location.state.profiles : null,
    savings: location.state ? location.state.savings : null,
    allocations: location.state ? location.state.allocations : null,
    income: location.state ? location.state.income : null,
    expenses: location.state ? location.state.expenses : null
  });

  useEffect(() => {
    if (window.gtag) window.gtag('event', 'page_view', { 'page_path': window.location.pathname + window.location.search + '#' + tab });
  }, [tab]);

  function handleNextTab(key, sections, nextTab) {
    handleNext(key, sections, () => setTab(nextTab));
  }

  function handleNext(key, sections, onSuccess) {
    var e = validators.validateAll(fields, sections);
    setErrors(e);

    if ((key && validators.isValid(e[key])) || validators.isValid(e))
      onSuccess();
  }

  function handleCalculate() {
    setLoading(true);

    api.calculateScore(fields)
      .then(r => {
        setLoading(false);
        navigate(`/score/${r.data.id}`, { state: {...r.data, inputs: fields} });
      })
      .catch(e => {
        setLoading(false);
      });
  }

  return <>
    <Nav justify variant="pills" activeKey={tab} className="mx-3 mb-3 nav-underline" onSelect={key => setTab(key)}>
      <Nav.Item>
        <Nav.Link eventKey="profiles" className={(errors && !validators.isValid(errors.profiles)) ? 'invalid' : ''}>Personal Details</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="savings" className={(errors && !validators.isValid(errors.savings)) ? 'invalid' : ''}>Savings &amp; Contributions</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="allocations" className={(errors && !validators.isValid(errors.allocations)) ? 'invalid' : ''}>Allocations</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="income" className={(errors && !validators.isValid(errors.income)) ? 'invalid' : ''}>Income in Retirement</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="expenses" className={(errors && !validators.isValid(errors.expenses)) ? 'invalid' : ''}>Expenses in Retirement</Nav.Link>
      </Nav.Item>
    </Nav>

    {tab === 'profiles' && 
      <Profiles value={fields.profiles} errors={errors.profiles}
        showName={false}
        submitLabel="Next" showCancel={false} buttonAlignment="right"
        onChange={v => setFields({ ...fields, profiles: v })}
        onSubmit={() => handleNextTab('profiles', ['profiles'], 'savings')}
      />
    }

    {tab === 'savings' && 
      <SavingsContributions value={fields.savings} errors={errors.savings}
        hasSecondary={fields && fields.profiles && fields.profiles.hasSecondary}
        submitLabel="Next" cancelLabel="Back" buttonAlignment="right"
        onChange={v => setFields({ ...fields, savings: v })}
        onSubmit={() => handleNextTab('savings', ['profiles', 'savings'], 'allocations')}
        onCancel={() => setTab('profiles')}
      />
    }

    {tab === 'allocations' &&
      <Allocations value={fields.allocations} errors={errors.allocations}
        submitLabel="Next" cancelLabel="Back" buttonAlignment="right"
        onChange={v => setFields({ ...fields, allocations: v })}
        onSubmit={() => handleNextTab('allocations', ['profiles', 'savings', 'allocations'], 'income')}
        onCancel={() => setTab('savings')}
      />
    }

    {tab === 'income' && 
      <Income value={fields.income} errors={errors.income}
        primaryRetirementAge={(fields && fields.profiles && fields.profiles.primary && parseInt(fields.profiles.primary.retirementAge)) || null}
        secondaryRetirementAge={(fields && fields.profiles && fields.profiles.secondary && parseInt(fields.profiles.secondary.retirementAge)) || null}
        hasSecondary={fields && fields.profiles && fields.profiles.hasSecondary}
        showSocialSecurityLink={false}
        submitLabel="Next" cancelLabel="Back" buttonAlignment="right"
        onChange={v => setFields({ ...fields, income: v })}
        onSubmit={() => handleNextTab('income', ['profiles', 'savings', 'allocations', 'income'], 'expenses')}
        onCancel={() => setTab('allocations')}
      />
    }
    
    {tab === 'expenses' && 
      <Expenses value={fields.expenses} errors={errors.expenses}
        hasSecondary={fields && fields.profiles && fields.profiles.hasSecondary}
        submitLabel="Calculate Your Score" cancelLabel="Back" buttonAlignment="right"
        onChange={v => setFields({ ...fields, expenses: v })}
        onSubmit={() => handleNext(null, null, handleCalculate)}
        onCancel={() => setTab('income')}
      />
    }

    <LoadingModal loading={loading} />
  </>;
}
