import axios from 'axios';
import moment from 'moment';
import Cookies from 'js-cookie';
import config from '../config';

function getToken() {
  const cookie = Cookies.get(config.COOKIES_TOKEN_KEY);
  const token = cookie && JSON.parse(cookie);
  const expires_in = token && token.expires_in && moment(parseInt(token.expires_in));

  if (expires_in && expires_in.isAfter()) {
    return Promise.resolve(token);
  }
  else if (token && token.refresh_token) {
    return refreshToken(token.refresh_token);
  }
  else {
    return Promise.reject();
  }
}

function refreshToken(refresh_token) {
  const params = new URLSearchParams();
  params.append('grant_type', 'refresh_token');
  params.append('refresh_token', refresh_token);

  return axios
    .post(`${config.API_URL}/connect/token`, params)
    .catch(e => {
      if (e && e.response) {
        const error = new Error();
        error.cancelled = axios.isCancel(e);
        error.status = e.response.status;
        throw error;
      }
      else
        throw e;
    })
    .then(r => {
      const token = convertToken({...r.data, refresh_token });
      saveToken(token);
      return token;
    });
}

function login(fields) {
  const params = new URLSearchParams();
  params.append('grant_type', 'password');
  params.append('username', fields.email);
  params.append('password', fields.password);
  params.append('scope', 'offline_access');

  return axios
    .post(`${config.API_URL}/connect/token`, params)
    .catch(e => {
      if (e && e.response) {
        const error = new Error();
        error.cancelled = axios.isCancel(e);
        error.status = e.response.status;
        throw error;
      }
      else
        throw e;
    })
    .then(r => {
      const token = convertToken(r.data);
      saveToken(token);
      return token;
    });
}

function convertToken(data) {
  return {
    access_token: data.access_token,
    refresh_token: data.refresh_token,
    expires_in: moment().add(data.expires_in - 60, 'seconds').valueOf()
  };
}

function saveToken(data) {
  Cookies.set(
    config.COOKIES_TOKEN_KEY,
    JSON.stringify(data),
    { expires: 60, domain: config.COOKIES_DOMAIN }
  );
}

function logout() {
  Cookies.remove(config.COOKIES_TOKEN_KEY, { domain: config.COOKIES_DOMAIN });
}

export default {
  getToken,
  login,
  logout
}