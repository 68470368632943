import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import errorImage from '../../images/error-icon.svg';

export default function Error({ onClick }) {
  return <>
    <Row className="justify-content-center align-items-center" style={{minHeight: '50vh'}}>
      <Col className="p-3" xs={11} sm={3}>
        <img src={errorImage} className="w-100" alt="" />
      </Col>
      <Col xs={11} sm={7} className="p-3">
        <h5 className="text-uppercase">Error</h5>
        <p>Something weird has happened. Are you still connected to the internet?</p>
        <Button variant="primary" onClick={onClick}>Try Again</Button>
      </Col>
    </Row>
  </>;
}
