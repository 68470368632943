import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from 'recharts';
import { NumberFormatControl, SubmitButtons } from './Shared';
import { InvalidFeedback } from '../Shared/Helpers';
import { SwappingRule } from '../Shared/Layout';
import { findPreSelectedAllocation, formatValue } from '../../helpers';
import api from '../../services/api';
import constants from '../../constants';
import moment from 'moment';

function allocationSum(allocations) {
  return constants.allocations.reduce((total, curr) =>  {
    const v = parseFloat(allocations[curr.id]);
    return total + (isFinite(v) && !isNaN(v) ? v : 0);
  }, 0);
}

function decorateValue(value) {
  const innerValue = {...value} || {};
  constants.allocations.forEach(a => {
    innerValue[a.id] = innerValue[a.id] === null || innerValue[a.id] === undefined ? '' : innerValue[a.id];
  });
  return innerValue;
}

const ReturnsRow = ({ row, label }) => {
  return <Row className="align-items-center justify-content-between my-2">
    <Col xs="auto">
      <strong>
        {label}<br />
        {moment(row.from).format('YYYY')} - {moment(row.to).format('YYYY')}
      </strong>
    </Col>
    <Col xs="auto">
      {formatValue(row.rate * 100, 'percent')}
    </Col>
  </Row>
}

const Blurb = ({ returns, returnsLoading }) => {
  return <>
    <p>
      Tell us a little more about your savings.
      We'll tell you something too{!returns ? '.' : ''}
      
      {returns && <>
        &nbsp;– below are some important historical returns for the asset classes we've chosen to use in your RISE Score.
      </>}
    </p>

    {!returnsLoading && returns &&
      <div className="small">
        <ReturnsRow row={returns.highest1} label="Highest 1 Year" />
        <ReturnsRow row={returns.highest20} label="Highest 20 Years" />
        <ReturnsRow row={returns.average} label="Average" />
        <ReturnsRow row={returns.lowest20} label="Lowest 20 Years" />
        <ReturnsRow row={returns.lowest1} label="Lowest 1 Year" />

        <Row className="align-items-center justify-content-between">
          <Col xs="auto">
            <strong>
              Anticipated Future<br />
              20 Year Average
            </strong>
          </Col>
          <Col xs="auto">
            {formatValue(returns.future20 * 100, 'percent')}
          </Col>
        </Row>
      </div>
    }
  </>;
}

export default function Allocations({ value, errors, loading, hasSecondary = false, submitLabel, cancelLabel, showCancel, buttonAlignment, onChange, onSubmit, onCancel }) {
  const innerValue = decorateValue(value);
  errors = errors || {};
  const sum = allocationSum(innerValue);

  const chartData = sum > 0 
    ? constants.allocations.map(a => {
      return { name: a.label, value: parseFloat(innerValue[a.id]) || 0 }
    })
    : [{ name: null, value: 1 }];

  const selectedPreAllocation = findPreSelectedAllocation(innerValue);

  function setPreAllocation(index) {
    var preallocation = constants.preallocations[index];

    constants.allocations.forEach((a, i) => {
      innerValue[a.id] = `${preallocation.allocations[a.id] || ''}`;
    })

    onChange({ ...innerValue });
  }

  function setValue(name, v) {
    innerValue[name] = v;
    onChange({ ...innerValue });
  }

  const [returnsLoading, setReturnsLoading] = useState(false);
  const [returns, setReturns] = useState(null);
  useEffect(() => {
    const innerValue = decorateValue(value);
    if (allocationSum(innerValue) === 100) {
      setReturnsLoading(true);
      api.getReturns(innerValue)
        .then(r => {
          setReturns(r.data);
          setReturnsLoading(false);
        })
        .catch(e => {
          setReturnsLoading(false);
        });
    }
     else {
      setReturns(null);
    }
  }, [value]);

  return <>
    <Row>
      <Col lg={8} className="p-3">
        <Form noValidate autoComplete="off" onSubmit={(e) => { e.preventDefault(); onSubmit(); return false; }}>
          <div className="d-lg-none mb-3">
            <Blurb returns={returns} />
            <hr />
          </div>

          <h5 className="text-uppercase">Allocations</h5>
            <p className="mb-0">
              How would you assign the total savings you entered under the following categories?
              We just need percentages, and they should add up to 100%.
              But, if you are not sure, you can always choose from one of the risk profiles below.
              This will automatically apportion your savings using these industry standard definitions.
            </p>

            <div className="btn-group-toggle p-3 d-flex justify-content-center" style={{ flexWrap: 'wrap' }}>

              {constants.preallocations.map((p, i) => 
                <label key={i} className={`btn btn-sm btn-outline-primary m-1 ${selectedPreAllocation === i && 'active'}`}>
                  <input type="radio" checked={selectedPreAllocation === i && 'active'} onChange={() => setPreAllocation(i)} disabled={loading} /> {p.label}
                </label>
              )}
              <label className={`btn btn-sm btn-outline-primary m-1 ${!selectedPreAllocation && selectedPreAllocation !== 0 && 'active'}`}>
                <input type="radio" checked={!selectedPreAllocation && selectedPreAllocation !== 0} onChange={() => onChange({})} disabled={loading} /> Custom
              </label>
            </div>

            <Row>
              <Col sm={4}>

                {constants.allocations.map((a, i) =>
                  <Form.Group key={i} controlId={`form${a.id}`}>
                    <Form.Label>{a.label}</Form.Label>
                    <NumberFormatControl value={innerValue[a.id]} numberStyle="percent" onChange={e => setValue(a.id, e.target.value)} isInvalid={!!errors[a.id]} disabled={loading} />
                    <InvalidFeedback error={errors[a.id]} />
                  </Form.Group>
                )}

              </Col>
            
              <Col sm={8}>
                <ResponsiveContainer minHeight={300}>
                  <PieChart>
                    <defs>
                      {constants.colors.map((c, i) =>
                        <linearGradient key={`color-${i}`} id={`color${i}`} x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor={c} stopOpacity={0.9}/>
                          <stop offset="95%" stopColor={c} stopOpacity={0.9}/>
                        </linearGradient>
                      )}
                    </defs>
                    <Pie isAnimationActive={false} isUpdateAnimationActive={true} dataKey="value" data={chartData} labelLine={false} outerRadius="90%">
                      {
                        (chartData || []).map((e, i) => <Cell key={i} fill={`url(#color${i % constants.colors.length})`} stroke={null} />)
                      }
                    </Pie>
                    {sum > 0 && <Tooltip formatter={v => { return v + '%' }} />}
                  </PieChart>
                </ResponsiveContainer>
              </Col>

            </Row>
            

            <Form.Group controlId="formTotal">
              <Form.Label>Total</Form.Label>
              <h4 className={!!errors.total ? 'is-invalid' : ''}>{sum}%</h4>
              <InvalidFeedback error={errors.total} />
            </Form.Group>

            <hr />

            <SubmitButtons {...{loading, submitLabel, cancelLabel, showCancel, buttonAlignment, onSubmit, onCancel}} />
          </Form>
      </Col>

      <SwappingRule breakpoint="sm" />

      <Col lg={4} className="p-3 d-none d-lg-block">

        <div>
          <Blurb returns={returns} />
        </div>

      </Col>

    </Row>
  
  </>
}