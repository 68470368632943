import React from 'react';
import { Form, OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap';

export function InvalidFeedback({ error }) {
  const hasError = !!error && (!Array.isArray(error) || error.length > 0);

  if (!hasError)
    return null;

  return <Form.Control.Feedback type="invalid">
    {!Array.isArray(error) && error}
    {Array.isArray(error) && error.map((e, i) => <React.Fragment key={i}>
      {i > 0 && <br />}
      {e}
    </React.Fragment>)}
  </Form.Control.Feedback>;
}

export function Tooltip({ content, children, placement = 'bottom' }) {
  return <OverlayTrigger
    placement={placement}
    delay={{ show: 250, hide: 400 }}
    overlay={(props) => <BootstrapTooltip {...props}>{content}</BootstrapTooltip>}
  >
    <div style={{display: 'inline-block', paddingLeft: '0.33rem', paddingRight: '0.33rem', cursor: 'pointer', color: '#ff6400'}}>
      {children}
    </div>
  </OverlayTrigger>
}