import React, { useState } from 'react';
import { Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { InvalidFeedback } from './Shared/Helpers';
import api from '../services/api';
import validators from '../services/validators';
import { parseQuery } from '../helpers';

const ResetPassword = ({ navigate, location }) => {
  const query = parseQuery(location.search);
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  function handleSubmit() {
    const e = validators.validateResetPassword(fields);
    setErrors(e);

    if (validators.isValid(e)) {
      setLoading(true);
      api.resetPassword({ email: query.email, token: query.token, password: fields.password })
        .then(r => {
          setLoading(false);
          navigate('/login', { state: { passwordReset: true } });
        })
        .catch(e => {
          e.status === 400 && setErrors(e.errors);
          setLoading(false);
        });
    }
  }

  return <>
    <Row className="justify-content-center align-items-center" style={{minHeight: '50vh'}}>
      <Col lg={6} className="p-4 p-lg-5 justify-content-center d-flex align-items-center">
        <div className="w-100">

          <h5 className="text-uppercase mb-3">Reset Your Password</h5>

          <Form noValidate autoComplete="off" onSubmit={(e) => { e.preventDefault(); handleSubmit(); return false; }}>

            {!!errors[''] && <Alert variant="danger">{errors['']}</Alert> }

            <Form.Group as={Col} controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" plaintext readOnly value={(query && query.email) || ''} onChange={e => setFields({...fields, email: e.target.value})} isInvalid={!!errors.email} disabled={loading} />
              <InvalidFeedback error={errors.email} />
            </Form.Group>

            <Form.Group as={Col} controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" value={fields.password || ''} onChange={e => setFields({...fields, password: e.target.value})} isInvalid={!!errors.password} disabled={loading} />
              <InvalidFeedback error={errors.password} />
            </Form.Group>

            <Form.Group as={Col} className="mb-4">
              <Button variant="primary" type="submit" disabled={loading} className="w-100">
                Reset Password
              </Button>
            </Form.Group>
              
          </Form>
        </div>
      </Col>
    </Row>
  </>;
};

export default ResetPassword;