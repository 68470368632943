import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import constants from '../../constants';
import { scoreIndex } from '../../helpers';

const ScoreExplained = ({ score, noTips }) => {
  const index = scoreIndex(score);
  const label = index != null ? constants.scoreRanges[index].label : '';
  const progress = index != null ? constants.scoreRanges[index].progress : '';

  return <>
    <h5 className="text-uppercase">The RISE Score Explained</h5>
    <p>
      <>The RISE Score looks just like your FICO score - it is a single value between 0 and 850 that helps you assess how well
      your income, when combined with your savings, will cover basic living expenses and healthcare costs in retirement.
      The higher the better. To calculate your score, we take the data you have provided and run through tens of thousands
      of future scenarios that take into account </>
      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Living longer than expected</Tooltip>}>
        <span className="pseudo-link">longevity risk</span>
      </OverlayTrigger>
      <>, </>
      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Markets not performing as well as you were hoping</Tooltip>}>
        <span className="pseudo-link">market risk</span>
      </OverlayTrigger>
      <>, </>
      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Reduction in your purchasing power</Tooltip>}>
        <span className="pseudo-link">inflation risk</span>
      </OverlayTrigger>
      <> and </>
      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Withdrawing too much, too soon</Tooltip>}>
        <span className="pseudo-link">sequence of returns risk</span>
      </OverlayTrigger>.
    </p>
    <p>
      Your RISE Score&trade; of <strong>{score}</strong> is <strong>{label}</strong> and represents <>{progress}</> progress toward meeting your retirement goals.
      {!noTips && <>At the end of this report, you will find some tips on how you might improve your score.</>}
    </p>
  </>;
};

export default ScoreExplained;