import React, { useState, useEffect } from 'react';
import { ADD_ACCOUNT, EMAIL_UPDATED, PASSWORD_CHANGED, NOTIFICATIONS_CHANGED } from '../../store/actionTypes';
import { Row, Col, Button, Form, Alert, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { InvalidFeedback } from '../Shared/Helpers';
import api from '../../services/api';
import auth from '../../services/auth';
import validators from '../../services/validators';
import moment from 'moment';
import { navigate } from '@reach/router';


const EmailForm = connect()(({ email, dispatch }) => {
  const [editMode, setEditMode] = useState(false);
  const [fields, setFields] = useState({ email });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  function handleCancel() {
    setEditMode(false);
  }

  function handleSubmit() {
    var e = validators.validateUpdateEmail(fields);
    setErrors(e);

    if (validators.isValid(e)) {
      setLoading(true);
      api.updateEmail(fields)
        .then(r => {
          dispatch({ type: ADD_ACCOUNT, account: r.data });
          dispatch({ type: EMAIL_UPDATED, timestamp: moment.utc() });
          setLoading(false);
          setEditMode(false);
        })
        .catch(e => {
          e.status === 400 && setErrors(e.errors);
          setLoading(false);
        });
    }
  }

  if (!editMode)
    return <Form.Group>
      <Form.Label>Email Address</Form.Label>
      <p className="mb-0">
        {email}
        &nbsp;
        <Button variant="link" onClick={() => { setFields({ email }); setEditMode(true); }}>Edit</Button>
      </p>
    </Form.Group>;

  return <Form noValidate autoComplete="off" className="pr-3" onSubmit={(e) => { e.preventDefault(); handleSubmit(); return false; }}>

    {!!errors[''] && <Alert variant="danger">{errors['']}</Alert>}

    <Form.Group as={Col} controlId="formEmail">
      <Form.Label>New Email Address</Form.Label>
      <Form.Control type="email" value={fields.email || ''} onChange={e => setFields({ email: e.target.value })} isInvalid={!!errors.email} disabled={loading} />
      <InvalidFeedback error={errors.email} />
    </Form.Group>

    <Row>
      <Col xs="auto">
        <Button variant="primary" type="submit" disabled={loading} className="my-1">
          Save
        </Button> &nbsp;
        
        <Button variant="outline-primary" onClick={handleCancel} disabled={loading} className="my-1">
          Cancel
        </Button>
      </Col>
    </Row>

  </Form>;
});

const PasswordForm = connect()(({ dispatch }) => {
  const [editMode, setEditMode] = useState(false);
  const [fields, setFields] = useState({ currentPassword: null, newPassword: null });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  function handleCancel() {
    setEditMode(false);
  }

  function handleSubmit() {
    var e = validators.validateChangePassword(fields);
    setErrors(e);

    if (validators.isValid(e)) {
      setLoading(true);
      api.changePassword(fields)
        .then(r => {
          dispatch({ type: ADD_ACCOUNT, account: r.data });
          dispatch({ type: PASSWORD_CHANGED, timestamp: moment.utc() });
          setLoading(false);
          setEditMode(false);
        })
        .catch(e => {
          e.status === 400 && setErrors(e.errors);
          setLoading(false);
        });
    }
  }

  if (!editMode)
    return <Form.Group>
      <Form.Label>Password</Form.Label>
      <p className="mb-0">
        ********
        &nbsp;
        <Button variant="link" onClick={() => { setFields({ currentPassword: null, newPassword: null }); setEditMode(true); }}>Edit</Button>
      </p>
    </Form.Group>;

  return <Form noValidate autoComplete="off" className="pr-3" onSubmit={(e) => { e.preventDefault(); handleSubmit(); return false; }}>

    {!!errors[''] && <Alert variant="danger">{errors['']}</Alert>}

    <Form.Group as={Col} controlId="formCurrentPassword">
      <Form.Label>Current Password</Form.Label>
      <Form.Control type="password" value={fields.currentPassword || ''} onChange={e => setFields({...fields, currentPassword: e.target.value})} isInvalid={!!errors.currentPassword} disabled={loading} />
      <InvalidFeedback error={errors.currentPassword} />
    </Form.Group>

    <Form.Group as={Col} controlId="formNewPassword">
      <Form.Label>New Password</Form.Label>
      <Form.Control type="password" value={fields.newPassword || ''} onChange={e => setFields({...fields, newPassword: e.target.value})} isInvalid={!!errors.newPassword} disabled={loading} />
      <InvalidFeedback error={errors.newPassword} />
    </Form.Group>

    <Row>
      <Col xs="auto">
        <Button variant="primary" type="submit" disabled={loading} className="my-1">
          Save
        </Button> &nbsp;
        
        <Button variant="outline-primary" onClick={handleCancel} disabled={loading} className="my-1">
          Cancel
        </Button>
      </Col>
    </Row>

  </Form>;
});

const NotificationForm = connect()(({ id, notificationId, value, label, dispatch }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  function handleSubmit(newValue) {
    setLoading(true);
    const fields = {};
    fields[notificationId] = newValue;
    api.changeNotifications(fields)
      .then(r => {
        dispatch({ type: ADD_ACCOUNT, account: r.data });
        dispatch({ type: NOTIFICATIONS_CHANGED, timestamp: moment.utc() });
        setLoading(false);
      })
      .catch(e => {
        e.status === 400 && setErrors(e.errors);
        setLoading(false);
      });
  }
  

  return <Form.Group controlId={id}>
    <Form.Check
      type="switch" id={`${id}Switch"`} label={label}
      checked={value || false} disabled={loading}
      onChange={(e) => handleSubmit(e.target.checked)}
    />
    <InvalidFeedback error={errors.newPassword} />
  </Form.Group>;
});

const CloseAccountModal = ({ show, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  function handleCloseAccount() {
    setLoading(true);
    api.closeAccount()
      .then(r => {
        auth.logout();
        navigate('/login', { state: { accountClosed: true } });
      })
      .catch(e => {
        e.status === 400 && setErrors(e.errors);
        setLoading(false);
      });
  }

  return <Modal show={show} onHide={() => !loading && onClose()} animation={false}>
    <Modal.Header closeButton>
      <Modal.Title as="h5" className="text-uppercase">
        Close your account
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>Closing your RISE Score account will remove your ability to log back into the service, as well as removing all data, reports and tips that have been generated for you.</p>
      <p>There is no turning back once you click on <strong>Close Account</strong>.</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="success" onClick={onClose} disabled={loading}>
        Back to Safety
      </Button>
      <Button variant="danger" onClick={handleCloseAccount} disabled={loading}>
        Close Account
      </Button>
    </Modal.Footer>
  </Modal>
};

const Account = ({ account, dispatch }) => {
  const [loading, setLoading] = useState(false);
  const [showCloseAlert, setShowCloseAlert] = useState(false);

  useEffect(() => {
    setLoading(true);
    api.getAccount()
      .then(r => {
        dispatch({ type: ADD_ACCOUNT, account: r.data });
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
      });
  }, [dispatch]);
  
  if (!account)
    return <></>;

  return <>
    <Row>
      <Col sm={8} className="p-3">

        <Row className="justify-content-center">
          <Col xs="auto" className="p-3">
            {account.emailUpdated && moment.utc().diff(account.emailUpdated) < 5000 && <Alert variant="success">Your e-mail was successfully updated.</Alert>}
            {account.passwordChanged && moment.utc().diff(account.passwordChanged) < 5000 && <Alert variant="success">Your password was successfully changed.</Alert>}
            {account.notificationsChanged && moment.utc().diff(account.notificationsChanged) < 5000 && <Alert variant="success">Your notifications were successfully updated.</Alert>}
          </Col>
        </Row>

        <h5 className="text-uppercase">Sign In</h5>

        <Row>
          <Col xs={6}>
            <EmailForm email={account.email} />
          </Col>

          <Col xs={6}>
            <PasswordForm />
          </Col>
        </Row>

        <hr />
          
        <h5 className="text-uppercase">Notifications</h5>
        <p></p>

        <NotificationForm
          id="formNotificationsScoreUpdate"
          notificationId="notificationsScoreUpdates"
          value={account.notificationsScoreUpdates}
          label="Receive regular updates on your RISE Score."
        />

        <NotificationForm
          id="formNotificationsEducation"
          notificationId="notificationsEducation"
          value={account.notificationsEducation}
          label="Receive educational material on retirement from industry experts."
        />

        <hr />
        
        <h5 className="text-uppercase">Close Account</h5>
        <p>We're big on privacy. If you are all done, you can close your account and we'll remove all information associated with it.</p>
        <Button variant="danger" onClick={() => setShowCloseAlert(true)}>Close Account</Button>
        
      </Col>
    </Row>

    <CloseAccountModal show={showCloseAlert} onClose={() => setShowCloseAlert(false)} />
  </>
};

export default connect(state => ({
  account: state.account
}))(Account);