import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import ScoreTiles from './ScoreTiles';
import api from '../../services/api';
import { formatValue } from '../../helpers';

const increments = [
  { amount: 50, count: 8 },
  { amount: 100, count: 16 },
  { amount: 250, count: 12 },
  { amount: 500, count: 10 },
  { amount: 1000, count: 10 }
];

const calculatedIncrements = (() => {
  const calculated = [0];
  for (var i = 0; i < increments.length; ++i) {
    const inc = increments[i];
    for (var j = 0; j < inc.count; ++j)
      calculated.push(calculated[calculated.length - 1] + inc.amount);
  }
  return calculated;
})();

const IncomeSlider = ({ name, isSecondary, value, disabled, onChange }) => {
  const label = name || (!isSecondary ? 'You' : 'Your Spouse');
  
  return <Row className="justify-content-center">
    <Col xs={12} className="text-center py-4">
      <Row>
        <Col>
          <label htmlFor="customRange" className="text-center">{label} earning an additional <strong>{formatValue(calculatedIncrements[value], 'currency')}</strong> per month in retirement</label>
          <input type="range" className="custom-range" id="customRange" min={0} max={calculatedIncrements.length - 1}
            disabled={disabled}
            value={value}
            onChange={e => onChange(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6} className="text-left">
          <strong>{formatValue(calculatedIncrements[0], 'currency')}</strong>
        </Col>
        <Col xs={6} className="text-right">
          <strong>{formatValue(calculatedIncrements[calculatedIncrements.length - 1], 'currency')}</strong>
        </Col>
      </Row>
    </Col>
  </Row>;
}

const Income = ({ profile }) => {
  const hasSecondary = profile.profiles && profile.profiles.secondary && profile.profiles.secondary.yearOfBirth && profile.profiles.secondary.retirementAge ? true : false;
  const [primaryIncome, setPrimaryIncome] = useState(0);
  const [secondaryIncome, setSecondaryIncome] = useState(hasSecondary ? 0 : null);
  const timeout = useRef(null);
  const cancelTokenSource = useRef(null);
  const [loading, setLoading] = useState(true);
  const [locked, setLocked] = useState(true);
  const [scores, setScores] = useState(null);

  useEffect(() => {
    setLoading(true);

    if (timeout.current) clearTimeout(timeout.current);
    if (cancelTokenSource.current) cancelTokenSource.current.cancel('Cancelled');
    
    timeout.current = setTimeout(() => {
      setLocked(true);
      let primaryIncomeAmount = (primaryIncome !== null && parseInt(primaryIncome) >= 0)
        ? calculatedIncrements[parseInt(primaryIncome)]
        : null;
      let secondaryIncomeAmount = (secondaryIncome !== null && parseInt(secondaryIncome) >= 0)
        ? calculatedIncrements[parseInt(secondaryIncome)]
        : null;

      cancelTokenSource.current = api.getCancelTokenSource();
      api.simulatorIncome(primaryIncomeAmount, secondaryIncomeAmount, cancelTokenSource.current.token)
        .then(r => {
          setScores(r.data);
          setLoading(false);
          setLocked(false);
        })
        .catch(e => {
          if (!e.cancelled) {
            setLoading(false);
            setLocked(false);
          }
        });
    }, 1000);

    return function cleanup() {
      if (timeout.current) clearTimeout(timeout.current);
      if (cancelTokenSource.current) cancelTokenSource.current.cancel('Cancelled');
    }
  }, [primaryIncome, secondaryIncome]);

  return <Row>
    <ScoreTiles scores={scores} loading={loading} />

    <Col xs={12} className="p-3">
      <h5 className="text-uppercase mb-0">Additional Income in Retirement</h5>

      <IncomeSlider name={profile.profiles.primary.name} isSecondary={false} value={primaryIncome}
        onChange={v => setPrimaryIncome(v)} disabled={locked} />

      {hasSecondary && <IncomeSlider name={profile.profiles.secondary.name} isSecondary={true} value={secondaryIncome}
        onChange={v => setSecondaryIncome(v)} disabled={locked} />}

      <Row>
        <Col>
          <p>
            You might be the type of retiree that won't be able to sit still, and may like to work part-time.
            The income you earn from your part-time work could offset or even delay having to withdraw money from your savings portfolio, helping it grow futher.
          </p>
        </Col>
      </Row>
    </Col>
  </Row>;
}

export default connect(state => ({
  profile: state.profile
}))(Income);