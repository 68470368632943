import { combineReducers } from 'redux';
import isInitialized from './isInitialized';
import isLoggedIn from './isLoggedIn';
import profile from './profiles';
import account from './account';
import reports from './reports';

export default combineReducers({
    isInitialized,
    isLoggedIn,
    profile,
    account,
    reports
});