import { ADD_PROFILES, RESET } from '../actionTypes';

const initialState = {};

export default function(state = initialState, action) {

  switch (action.type) {
    case ADD_PROFILES: {
      return {...state, ...action.profiles};
    }
    case RESET: {
      return initialState;
    }
    default: {
      return state;
    }
  }

}