import React from 'react';
import { Row, Col } from 'react-bootstrap';
import errorImage from '../images/error-icon.svg';

export default function NotFound() {
  return <>
    <Row className="justify-content-center align-items-center" style={{minHeight: '50vh'}}>
      <Col className="p-3" xs={11} sm={3}>
        <img src={errorImage} className="w-100" alt="" />
      </Col>
      <Col xs={11} sm={7} className="p-3">
        <h5 className="text-uppercase">Page Not Found</h5>
        <p className="mb-0">The page you have requested does not exist. The address may have changed or the page is no longer available.</p>
      </Col>
    </Row>
  </>;
}
