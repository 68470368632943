import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from 'recharts';
import ScoreTiles from './ScoreTiles';
import api from '../../services/api';
import constants from '../../constants';
import { findPreSelectedAllocation } from '../../helpers';

const AllocationsSlider = ({ value, disabled, onChange }) => {
  let label = constants.preallocations[value].label;
  return <Row className="justify-content-center">
    <Col xs={12} className="text-center py-4">
      <Row>
        <Col>
          <label htmlFor="customRange" className="text-center">Using {label.startsWith('A') ? 'an' : 'a'} <strong>{label}</strong> allocation for your savings portfolio</label>
          <input type="range" className="custom-range" id="customRange" min={0} max={constants.preallocations.length - 1}
            disabled={disabled}
            value={value}
            onChange={e => onChange(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6} className="text-left">
          <strong>{constants.preallocations[0].label}</strong>
        </Col>
        <Col xs={6} className="text-right">
          <strong>{constants.preallocations[constants.preallocations.length - 1].label}</strong>
        </Col>
      </Row>
    </Col>
  </Row>;
}

const Allocations = ({ profile }) => {
  const [allocations, setAllocations] = useState(findPreSelectedAllocation(profile.allocations) || 0);
  const timeout = useRef(null);
  const cancelTokenSource = useRef(null);
  const [loading, setLoading] = useState(true);
  const [locked, setLocked] = useState(true);
  const [scores, setScores] = useState(null);
  const chartData = constants.allocations.map(a => {
    return { name: a.label, value: constants.preallocations[allocations].allocations[a.id] || 0 }
  });

  useEffect(() => {
    setLoading(true);

    if (timeout.current) clearTimeout(timeout.current);
    if (cancelTokenSource.current) cancelTokenSource.current.cancel('Cancelled');
    
    timeout.current = setTimeout(() => {
      setLocked(true);
      
      cancelTokenSource.current = api.getCancelTokenSource();
      api.simulatorAllocations(constants.preallocations[allocations].allocations, cancelTokenSource.current.token)
        .then(r => {
          setScores(r.data);
          setLoading(false);
          setLocked(false);
        })
        .catch(e => {
          if (!e.cancelled) {
            setLoading(false);
            setLocked(false);
          }
        });
    }, 1000);

    return function cleanup() {
      if (timeout.current) clearTimeout(timeout.current);
      if (cancelTokenSource.current) cancelTokenSource.current.cancel('Cancelled');
    }
  }, [allocations]);

  return <Row>
    <ScoreTiles scores={scores} loading={loading} />

    <Col xs={12} className="p-3">
      <h5 className="text-uppercase mb-0">Savings Allocation</h5>

      <AllocationsSlider value={allocations} onChange={v => setAllocations(v)} disabled={locked} />

      <Row>
        <Col>
          <p>
            Financial stability in retirement has a lot to do with your savings, but also with how you invest.
            Allocating your portfolio to different investment categories could change the way in which your savings grow over time.
            Being too conservative early in your savings journey could see you sacrifice larger returns and compounding.
            On the other hand, being more aggressive in your allocation closer to retirement could put your portfolio at risk of loss with less time to recover.
          </p>

          <p>
            The <strong>{constants.preallocations[allocations].label}</strong> allocation selected above invests your portfolio using the following allocation:
          </p>

          <Row className="align-items-center justify-content-center">
            <Col xs="auto" className="p-3">
              {chartData.map((a, i) =>
                (a.value > 0 ? <p key={i}><strong>{a.value}%</strong> in {a.name}</p> : null)
              )}
            </Col>
            <Col xs="auto" className="p-3">
              <ResponsiveContainer minWidth={200} minHeight={200}>
                <PieChart>
                  <defs>
                    {constants.colors.map((c, i) =>
                      <linearGradient key={`color-${i}`} id={`color${i}`} x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={c} stopOpacity={0.9}/>
                        <stop offset="95%" stopColor={c} stopOpacity={0.9}/>
                      </linearGradient>
                    )}
                  </defs>
                  <Pie isAnimationActive={false} isUpdateAnimationActive={true} dataKey="value" data={chartData} labelLine={false} outerRadius="90%">
                    {
                      (chartData || []).map((e, i) => <Cell key={i} fill={`url(#color${i % constants.colors.length})`} stroke={null} />)
                    }
                  </Pie>
                  <Tooltip formatter={(value) => { return value + '%' }} />
                </PieChart>
              </ResponsiveContainer>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  </Row>;
}

export default connect(state => ({
  profile: state.profile
}))(Allocations);