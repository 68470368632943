import React from 'react';
import { connect } from 'react-redux';
import { Link, navigate } from '@reach/router';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import config from '../../config';
import auth from '../../services/auth';
import { RESET } from '../../store/actionTypes';
import logo from '../../images/logo.svg';

const AppBar = ({ isLoggedIn, name, dispatch }) => {
  
  function logout() {
    auth.logout();
    dispatch({ type: RESET });
    navigate('/');
  }

  return (
    <Navbar expand="md" variant="dark" className="gradient-bg">
      <Container>
        <Navbar.Brand href={config.WEB_URL}>
          <img src={logo} alt="" style={{height: '1.25rem', lineHeight: '0.875rem'}} />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {isLoggedIn && <Nav.Link as={Link} to="/overview">Overview</Nav.Link>}

            {!isLoggedIn && 
              <NavDropdown title="Scores &amp; Tools">
                <NavDropdown.Item as={Link} to="/score">The RISE Score</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/social-security-benefit">Social Security Benefit Estimate</NavDropdown.Item>
              </NavDropdown>
            }
            {isLoggedIn && 
              <NavDropdown title="Scores &amp; Tools">
                <NavDropdown.Item as={Link} to="/report">Your RISE Score Report</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/simulator">RISE Score Simulator</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/social-security-benefit">Social Security Benefit Estimate</NavDropdown.Item>
              </NavDropdown>
            }
            <NavDropdown title="Education">
              <NavDropdown.Item href={`${config.WEB_URL}/education/methodology`}>The RISE Score Methodology</NavDropdown.Item>
              <NavDropdown.Item href={`${config.WEB_URL}/education/faq`}>Frequently Asked Questions</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav>
            {!isLoggedIn && <>
              <Nav.Link as={Link} to="/score">Get Started</Nav.Link>
              <Nav.Link as={Link} to="/login">Login</Nav.Link>
            </>}
            {isLoggedIn && 
              <NavDropdown title={name || 'You'} alignRight>
                <NavDropdown.Item as={Link} to="/profile">Your Profile</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#" onClick={() => logout()}>Sign out</NavDropdown.Item>
              </NavDropdown>
            }
          </Nav>
        </Navbar.Collapse>
          
      </Container>
    </Navbar>
  );
}

export default connect(state => {
  return {
    isLoggedIn: state.isLoggedIn,
    name: state.profile && state.profile.profiles && state.profile.profiles.primary && state.profile.profiles.primary.name
  }
})(AppBar);