import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { SwappingRule } from '../../components/Shared/Layout';

const ScoreStatistics = ({ data }) => {
  const [activeTab, setActiveTab] = useState(0);

  return <>
    <h5 className="text-uppercase mt-3 mx-3 mb-0">Summary Statistics</h5>

    {data &&
      <Row>
        <Col sm={3}>
          <div className="m-3" style={{borderBottom: activeTab === 0 ? 'solid 3px #ff6400' : null, cursor: 'pointer'}} onClick={() => setActiveTab(0)}>
            Income-to-Expense Ratio
            <h4>{data.cte90}-{data.cte0}%</h4>
          </div>
        </Col>
            
        <SwappingRule breakpoint="sm" />

        <Col sm={3}>
          <div className="m-3" style={{borderBottom: activeTab === 1 ? 'solid 3px #ff6400' : null, cursor: 'pointer'}} onClick={() => setActiveTab(1)}>
            Average Return
            <h4>{data.averageReturn}%</h4>
          </div>
        </Col>

        <SwappingRule breakpoint="sm" />

        <Col sm={3}>
          <div className="m-3" style={{borderBottom: activeTab === 2 ? 'solid 3px #ff6400' : null, cursor: 'pointer'}} onClick={() => setActiveTab(2)}>
            Average Inflation
            <h4>{data.averageInflation}%</h4>
          </div>
        </Col>

        <SwappingRule breakpoint="sm" />

        <Col sm={3}>
          <div className="m-3" style={{borderBottom: activeTab === 3 ? 'solid 3px #ff6400' : null, cursor: 'pointer'}} onClick={() => setActiveTab(3)}>
            Life Expectancy
            <h4>{data.primaryLifeExpectancy} {data.secondaryLifeExpectancy ? `& ${data.secondaryLifeExpectancy}` : ''} yrs</h4>
          </div>
        </Col>

        {activeTab === 0 && <Col xs={12} className="px-3 pb-3">
          <h6>Income-to-Expense Ratio</h6>
          <p>
            The expected income in retirement when we include portfolio withdrawals should cover {data.cte0}% of expenses in average scenarios and {data.cte90}% of expenses in the worst 10% of possible scenarios.
            The expected income in retirement when we exclude portfolio withdrawals should cover {data.cte0ExclWithdrawals}% of expenses in average scenarios and 90% of expenses in the worst {data.cte90ExclWithdrawals}% of scenarios.
          </p>
        </Col>}

        {activeTab === 1 && <Col xs={12} className="px-3 pb-3">
          <h6>Average Return</h6>
          <p>
            Based on the asset allocation entered for your retirement savings portfolio, the average return expected across all market scenarios is {data.averageReturn}%.
          </p>
        </Col>}

        {activeTab === 2 && <Col xs={12} className="px-3 pb-3">
          <h6>Average Inflation</h6>
          <p>
            We look at various inflation possibilities when we calculate your RISE Score - we do not use a single inflation assumption.
            But, it is interesting to know that the average inflation rate expected across all scenarios is {data.averageInflation}% ({data.averageInflationNonMedical}% for non-medical expenses and {data.averageInflationMedical}% for medical expenses).
          </p>
        </Col>}

        {activeTab === 3 && <Col xs={12} className="px-3 pb-3">
          <h6>Life Expectancy</h6>
          <p>
            {!data.secondaryLifeExpectancy
              ? `Your life expectancy is ${data.primaryLifeExpectancy} years`
              : `You and your spouse's life expectancies are both ${data.primaryLifeExpectancy} and ${data.secondaryLifeExpectancy} years respectively`}

            , based on an industry-accepted annuitant mortality table.

            When we calculate your RISE Score&trade;, however, we do not assume a single value for life expectancy.
            Rather, we test your income and savings against many possible life expectancy outcomes, or "longevity scenarios".
            Within these scenarios, the 25th-75th percentile of ages is {data.primaryLifeExpectancyLower}-{data.primaryLifeExpectancyUpper}
            {data.secondaryLifeExpectancy ? ` and ${data.secondaryLifeExpectancyLower}-${data.secondaryLifeExpectancyUpper}` : ''} years{data.secondaryLifeExpectancy ? ' respectively' : ''}.
          </p>
        </Col>}

      </Row>
    }
  </>;
};

export default ScoreStatistics;